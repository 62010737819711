import apiClient from "../axios.config";

const auditService = {
  posttPropertyAudit(payload) {
    return apiClient.post("/owner/properties/audited", payload);
  },
  getPropertyAuditImage(id) {
    return apiClient.get(`/owner/properties/audited/${id}`);
  },
};

export default auditService;
