import apiClient from "./axios.config";
import Cookies from "js-cookie";

const customerRegistration = {
  postCustomerRegistration(payload) {
    return apiClient.post("/customer/auth/register", payload);
  },

  postresendOtp(custGuid) {
    return apiClient.post(
      `/customer/auth/register/resend-otp?custGuid=${custGuid}`
    );
  },

  verifyOtp(custGuid, otp) {
    return apiClient
      .post(
        `/customer/auth/register/verify-otp?custGuid=${custGuid}&otp=${otp}`
      )
      .then((response) => {
        if (response.data && response.data.result) {
          const { accessToken } = response.data.result;
          Cookies.set("accessToken", accessToken);
        }
        return response;
      });
  },

  postRequestOtp(mobNo) {
    return apiClient.post(`/customer/auth/login?mobNo=${mobNo}`);
  },

  postResendOtpLogin(mobNo) {
    return apiClient.post(`/customer/auth/login/resend-otp?mobNo=${mobNo}`);
  },

  verifyOtpLogin(mobNo, otp) {
    return apiClient
      .post(`/customer/auth/login/verify-otp?mobNo=${mobNo}&otp=${otp}`)
      .then((response) => {
        if (response.data && response.data.result) {
          const { accessToken } = response.data.result;
          console.log("accessTokenService", accessToken);
          Cookies.set("accessToken", accessToken, { expires: 1 });
          // Cookies.set("accessToken", accessToken, { expires: 2 / (24 * 60) });
        }
        return response;
      })
      .catch((error) => {
        console.error("Failed to verify OTP login:", error);
        throw error;
      });
  },
};

export default customerRegistration;
