import apiClient from "../axios.config";

const bankDetailsServices = {
  postBankDetails(payload) {
    return apiClient.post("owner/bank", payload);
  },
  getBankDetails(payload) {
    return apiClient.get("owner/bank", payload);
  },

  updateBankDetails(payload) {
    return apiClient.put("owner/bank", payload);
  },
};

export default bankDetailsServices;
