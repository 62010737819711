import React, { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import FileDropper from "../common/dropper";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import excellentIcon from "../../assets/images/excellent.png";
import goodIcon from "../../assets/images/good.png";
import averageIcon from "../../assets/images/average.png";
import badIcon from "../../assets/images/bad.png";
import veryBadIcon from "../../assets/images/very-bad.png";
import { toast } from "react-toastify";
import SupportRequestService from "../../services/support-request-service";
import FeedbackService from "../../services/customer-services/feedback-service";

import { handleErrors } from "../../utils/errorHandler";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import { Card, CardContent } from "@mui/material";
import EventNoteIcon from "@mui/icons-material/EventNote"; // Example icon
import WorkIcon from "@mui/icons-material/Work"; // Example icon
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Example icon
import CloseIcon from "@mui/icons-material/Close"; // Example icon
import { TimelineOppositeContent } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
const SupportRequest = () => {
  const navigate = useNavigate();

  const [isShowModal, setIsShowModal] = useState(false);
  const [supportType, setSupportType] = useState("");
  const [description, setDescription] = useState("");
  const [comments, setComments] = useState("");
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [errors, setErrors] = useState({});
  const [supportTickets, setSupportTickets] = useState([]);
  const [supportGuid, setSupportGuid] = useState("");
  const [isEditing, setIsEditing] = useState(false); // New state for edit mode
  const [attachment, setAttachment] = useState([]); // Modify this to store an array
  const [feedbackStatus, setFeedbackStatus] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false); // New state to track submission status
  const [isTimelineModalOpen, setIsTimelineModalOpen] = useState(false); // State for timeline modal
  const [selectedTimelineDetails, setSelectedTimelineDetails] = useState("");
  // const [timelineData, setTimelineData] = useState([]); // Store timeline activities here
  const [selectedTicket, setSelectedTicket] = useState(null); // To store selected ticket data
  // const [isModalOpen, setIsModalOpen] = useState(false); // To control modal visibility
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  const ratingMap = {
    Excellent: 5,
    Good: 4,
    Average: 3,
    Bad: 2,
    "Very Bad": 1,
  };

  const ratingImageMap = {
    5: excellentIcon,
    4: goodIcon,
    3: averageIcon,
    2: badIcon,
    1: veryBadIcon,
  };

  const fetchSupportTickets = async () => {
    try {
      const response = await SupportRequestService.getSupportTickets();
      if (response.data.isSuccess) {
        setSupportTickets(response.data.result);
        fetchFeedback(response.data.result);
      }
    } catch (error) {
      const errorMessage = handleErrors(error);
      toast.error(errorMessage);
    }
  };

  const fetchFeedback = async (tickets) => {
    try {
      const response = await FeedbackService.getFeedbackByCustomer();
      if (response.data.isSuccess) {
        const feedbackData = response.data.result;
        const feedbackStatus = {};
        tickets.forEach((ticket) => {
          const feedback = feedbackData.find(
            (f) => f.supportGuid === ticket.supportGuid
          );
          if (feedback) {
            feedbackStatus[ticket.supportGuid] = feedback.rating;
          }
        });
        setFeedbackStatus(feedbackStatus);
      }
    } catch (error) {
      const errorMessage = handleErrors(error);
      toast.error(errorMessage);
    }
  };

  useEffect(() => {
    fetchSupportTickets();
  }, []);

  const hideFeedbackModel = () => {
    setIsShowModal(false);
    setSelectedFeedback(null);
    setComments("");
  };
  const showFeedbackModel = (guid) => {
    setSupportGuid(guid);
    setIsShowModal(true);
  };

  // Validation function
  const validateForm = () => {
    let formErrors = {};
    if (!supportType) {
      formErrors.supportType = "Please select a support type.";
    }
    return formErrors;
  };

  const formatDated = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(dateString)
      .toLocaleDateString("en-GB", options)
      .replace(/ /g, "-");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formErrors = validateForm();
    if (Object.keys(formErrors).length === 0) {
      setIsSubmitting(true);
      const formData = new FormData();
      formData.append("TypeOfSupport", supportType);
      formData.append("Description", description);

      attachment.forEach((file) => {
        formData.append("Attachment", file);
      });

      try {
        await SupportRequestService.postSupportRequest(formData);
        setIsShowModal(false);
        fetchSupportTickets();
        toast.success("Support details saved successfully!");
        setSupportType("");
        setDescription("");
        setAttachment([]);
      } catch (error) {
        let errorMessage = handleErrors(error);
        if (error.response) {
          if (error.response.status === 404) {
            errorMessage =
              error.response.data.message ||
              "No response received from the server. Please try again later.";
          } else if (error.response.status === 500) {
            errorMessage = error.response.data.message;
          }
        }
        toast.error(errorMessage);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setErrors(formErrors);
    }
  };
  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  const handleFeedbackSubmit = async () => {
    const feedbackErrors = validateFeedbackForm();

    if (Object.keys(feedbackErrors).length > 0) {
      setErrors(feedbackErrors);
      return;
    }

    const ratingInteger = ratingMap[selectedFeedback];

    try {
      // Ensure supportGuid is passed correctly
      if (!supportGuid) {
        toast.error("Support Guid is missing.");
        return;
      }

      await FeedbackService.postFeedback(supportGuid, ratingInteger, comments);
      toast.success("Feedback submitted successfully!");
      setFeedbackStatus((prevStatus) => ({
        ...prevStatus,
        [supportGuid]: ratingInteger,
      }));

      hideFeedbackModel(); // Close the modal after submission
    } catch (error) {
      let errorMessage = handleErrors(error);
      if (error.response) {
        if (error.response.status === 404) {
          errorMessage =
            error.response.data.message || "User Data is Not found";
        } else if (error.response.status === 500) {
          errorMessage = error.response.data.message;
        }
      }

      toast.error(errorMessage);
    }
  };

  // Validation function for feedback form
  const validateFeedbackForm = () => {
    let feedbackErrors = {};

    // Check if feedback is selected
    if (selectedFeedback === null) {
      feedbackErrors.selectedFeedback = "Please select a feedback rating.";
    }

    // Check if comments are provided
    if (!comments.trim()) {
      feedbackErrors.comments = "Please provide comments.";
    }

    return feedbackErrors;
  };

  const handleTimelineLinkClick = async (supportTicketGuid) => {
    try {
      const response = await SupportRequestService.getSupportTicketDetails(
        supportTicketGuid
      );

      if (response.data.isSuccess) {
        setSelectedTicket(response.data.result);

        setSelectedTimelineDetails(response.data.result.supportActivities);
        setIsTimelineModalOpen(true);
      } else {
        toast.error("Failed to fetch timeline details");
      }
    } catch (error) {
      let errorMessage = handleErrors(error);
      if (error.response) {
        if (error.response.status === 404) {
          errorMessage = error.response.data.message;
        } else if (error.response.status === 500) {
          errorMessage = error.response.data.message;
        }
        toast.error(errorMessage);
      }
    }
  };

  const getBadgeClass = (activityType) => {
    switch (activityType) {
      case "Created":
        return "badge-warning";
      case "Closed":
        return "badge-info";
      case "InProgress":
        return "badge-success";
      case "Resolved":
        return "badge-primary";
      default:
        return "badge-secondary";
    }
  };

  const iconMap = {
    Created: <EventNoteIcon />,
    InProgress: <WorkIcon />,
    Resolved: <CheckCircleIcon />,
    Closed: <CloseIcon />,
  };
  const colorMap = {
    Created: "secondary", // Purple
    InProgress: "primary", // Blue
    Resolved: "success", // Green
    Closed: "error", // Red
  };

  const isImage = (url) => {
    return /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(url);
  };

  const closeTimelineModal = () => setIsTimelineModalOpen(false);
  const openImageModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
    setSelectedImage("");
  };
  const getBadgeClasses = (activityType) => {
    switch (activityType) {
      case "Created":
        return "badge-warning";
      case "Closed":
        return "badge-info";
      case "InProgress":
        return "badge-success";
      case "Resolved":
        return "badge-primary";
      default:
        return "badge-secondary";
    }
  };

  const getStatusBadge = (activityType) => {
    const badgeClass = getBadgeClasses(activityType);
    return <span className={`badge ${badgeClass}`}>{activityType}</span>;
  };

  const imageBodyTemplate = (product) => {
    const feedbackRating = feedbackStatus[product.supportGuid];

    return (
      <div>
        {feedbackRating ? (
          <div className="feedback-icon">
            <img
              src={ratingImageMap[feedbackRating]}
              alt={feedbackRating}
              className="feedback-img"
              width="30"
              height="30"
            />
          </div>
        ) : product.supportStatus === "Closed" ? (
          <div
            className="give-feedback"
            onClick={() => {
              setSupportGuid(product.supportGuid);
              showFeedbackModel(product.supportGuid);
            }}
          >
            Give Feedback
          </div>
        ) : (
          "-"
        )}

        <Modal show={isShowModal} onHide={hideFeedbackModel}>
          <Modal.Header closeButton>
            <Modal.Title>Feedback</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="feedback-options">
              <div className="feedback-icons mb-3">
                {Object.keys(ratingMap).map((feedbackKey) => (
                  <div className="icon-wrap" key={feedbackKey}>
                    <input
                      type="radio"
                      name="feedback"
                      id={feedbackKey}
                      checked={selectedFeedback === feedbackKey}
                      onChange={() => setSelectedFeedback(feedbackKey)}
                    />
                    <label
                      htmlFor={feedbackKey}
                      className={
                        selectedFeedback === feedbackKey ? "active" : ""
                      }
                    >
                      <img
                        src={ratingImageMap[ratingMap[feedbackKey]]}
                        alt={feedbackKey}
                      />
                      <span>{feedbackKey}</span>
                    </label>
                  </div>
                ))}
              </div>
              {errors.selectedFeedback && (
                <div className="text-danger">{errors.selectedFeedback}</div>
              )}

              <Form.Group controlId="comments">
                <Form.Label>Comments</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Your Comments*"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                />
                {errors.comments && (
                  <div className="text-danger">{errors.comments}</div>
                )}
              </Form.Group>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={hideFeedbackModel}>
              Close
            </Button>
            <Button variant="primary" onClick={handleFeedbackSubmit}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  return (
    <div className="support-detail-wrap">
      {/* <ToastContainer /> */}
      <div className="my-account-title">
        <h2>Support</h2>
      </div>
      <Row>
        <Col>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="supportType">
              <Form.Label>Type of Support</Form.Label>
              <Form.Select
                value={supportType}
                onChange={(e) => setSupportType(e.target.value)}
              >
                <option value="">Select type of support</option>
                <option value="Common area">Common area</option>
                <option value="Electrical issue">Electrical issue</option>
                <option value="Flat furnishing">Flat furnishing</option>
                <option value="Flat renovation">Flat renovation</option>
                <option value="Furniture issue">Furniture issue</option>
                <option value="House keeping">House keeping</option>
                <option value="Lift issue">Lift issue</option>
                <option value="Offline marketing">Offline marketing</option>
                <option value="Painting">Painting</option>
                <option value="Pest control">Pest control</option>
                <option value="Plumbing issue">Plumbing issue</option>
                <option value="Porter">Porter</option>
                <option value="Water issue">Water issue</option>
                <option value="Water tanker">Water tanker</option>
              </Form.Select>
              {errors.supportType && (
                <div className="text-danger">{errors.supportType}</div>
              )}
            </Form.Group>
            <Form.Group controlId="description" className="mt-2 mb-2">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              {errors.description && (
                <div className="text-danger">{errors.description}</div>
              )}
            </Form.Group>
            <FileDropper onFileSelect={(files) => setAttachment(files)} />
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : isEditing ? "Update" : "Submit"}
            </Button>
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="renewal-property">
            <div className="my-account-subtitle">
              <h3>Previous Support Request</h3>
            </div>
            <DataTable
              value={supportTickets}
              paginator
              rows={10}
              showGridlines
              stripedRows
            >
              <Column
                field="createdOn"
                header="Date"
                body={(rowData) => formatDated(rowData.createdOn)}
              />
              <Column field="ticketId" header="Ticket ID" />
              <Column field="typeOfSupport" header="Type" />
              <Column
                field="supportStatus"
                header="Status"
                body={(rowData) => getStatusBadge(rowData.supportStatus)}
              />
              <Column field="description" header="Description" />
              <Column header="Feedback" body={imageBodyTemplate} />
              <Column
                header="Support Timeline"
                body={(rowData) => (
                  <Button
                    className="give-feedback"
                    variant="link"
                    onClick={() => handleTimelineLinkClick(rowData.supportGuid)}
                  >
                    View Timeline
                  </Button>
                )}
              />
            </DataTable>
          </div>
        </Col>
      </Row>

      <Dialog
        open={isTimelineModalOpen}
        onClose={closeTimelineModal}
        fullWidth
        maxWidth="md"
        className="support-timeline-dialog"
      >
        <DialogContent>
          <div className="custom-modal-title">
            <h2>Support Timeline Details</h2>
          </div>
          {selectedTicket ? (
            <ul className="st-info">
              <li>
                <strong>Ticket ID:</strong> {selectedTicket.ticketId}
              </li>
              <li>
                <strong>Created On:</strong>{" "}
                {new Date(selectedTicket.createdOn).toLocaleDateString()}
              </li>
              <li>
                <strong>Type of Support:</strong> {selectedTicket.typeOfSupport}
              </li>
            </ul>
          ) : (
            <div>Loading ticket details...</div>
          )}

          <Timeline position="alternate" className="support-timeline">
            {/* Reverse the timeline order */}
            {Array.isArray(selectedTimelineDetails) &&
            selectedTimelineDetails.length > 0 ? (
              [...selectedTimelineDetails].reverse().map((activity, index) => (
                <TimelineItem key={index}>
                  <TimelineOppositeContent
                    sx={{ m: "auto 0" }}
                    align="right"
                    variant="body2"
                    color="text.secondary"
                  >
                    {new Date(activity.updatedOn).toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot
                      color={colorMap[activity.activityType] || "grey"}
                    >
                      {iconMap[activity.activityType] || <WorkIcon />}
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>

                  <TimelineContent sx={{ py: "12px", px: 2 }}>
                    <Card variant="outlined" className="timeline-card">
                      <CardContent>
                        <div className="ticket-badge">
                          <span
                            className={`badge ${getBadgeClass(
                              activity.activityType
                            )}`}
                          >
                            {activity.activityType}
                          </span>
                        </div>
                        <div>{activity.remarks}</div>

                        {activity.attachment &&
                          isImage(activity.attachment) && (
                            <div className="mt-1">
                              <span>
                                <img
                                  src={activity.attachment}
                                  alt="attachment"
                                  style={{
                                    maxWidth: "100%",
                                    maxHeight: "70px",
                                    marginTop: "10px",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    openImageModal(activity.attachment)
                                  }
                                />
                              </span>
                            </div>
                          )}

                        {activity.attachment &&
                          !isImage(activity.attachment) && (
                            <div className="mt-1">
                              <strong>Attachment:</strong>
                              <a
                                href={activity.attachment}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                View Attachment
                              </a>
                            </div>
                          )}

                        <div className="mt-1 updated-by">
                          <strong>Updated by</strong>: {activity.updatedBy} on{" "}
                          {formatDated(
                            new Date(activity.updatedOn).toLocaleDateString()
                          )}
                        </div>
                      </CardContent>
                    </Card>
                  </TimelineContent>
                </TimelineItem>
              ))
            ) : (
              <Typography variant="body2">
                No timeline activities available.
              </Typography>
            )}
          </Timeline>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeTimelineModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Image Modal */}
      <Dialog open={isImageModalOpen} onClose={closeImageModal} fullWidth>
        <DialogTitle>Image Preview</DialogTitle>
        <DialogContent>
          <div style={{ textAlign: "center" }}>
            <img
              src={selectedImage}
              alt="Preview"
              style={{
                maxWidth: "100%",
                maxHeight: "600px",
                borderRadius: "5px",
              }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeImageModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SupportRequest;
