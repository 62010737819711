import React, { useState } from "react";
import { Button, Form, Alert } from "react-bootstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Row, Col } from "react-bootstrap";
import ownerForgotPassword from "../../services/owner-services/owner-forgot";
import { handleErrors } from "../../utils/errorHandler";

const OwnerChangePassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const validatePasswords = () => {
    if (!currentPassword || !newPassword || !confirmPassword) {
      setError("All fields are required.");
      return false;
    }
    if (newPassword !== confirmPassword) {
      setError("New passwords do not match.");
      return false;
    }
    if (newPassword.length < 8) {
      setError("Password must be at least 8 characters long.");
      return false;
    }
    setError("");
    return true;
  };

  const handleChangePassword = async () => {
    if (!validatePasswords()) return;

    setIsSaving(true);
    setSuccess("");
    setError("");
    try {
      const response = await ownerForgotPassword.ownerForgot({
        currentPassword,
        newPassword,
      });

      if (response && response.isSuccess) {
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        console.log(response.isSuccess);
        setSuccess(response.message);
      } else {
        setError(response.message || "An error occurred. Please try again.");
      }
    } catch (error) {
      let errorMessage = handleErrors(error);
      if (error.response) {
        if (error.response.status === 404) {
          errorMessage = error.response.data.message;
        } else if (error.response.status === 500) {
          errorMessage = error.response.data.message;
        }
      }
      setError(errorMessage);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="change-password-form">
      <h3>Change Password</h3>
      <Row>
        <Col xs={8}> {error && <Alert variant="danger">{error}</Alert>}</Col>
        <Col xs={8}>
          {" "}
          {success && <Alert variant="success">{success}</Alert>}
        </Col>
        <Col xs={8}>
          <Form.Group className="mb-3">
            <Form.Label>Current Password</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter current password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col xs={8}>
          <Form.Group className="mb-3">
            <Form.Label>New Password</Form.Label>
            <div className="position-relative">
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <span
                className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
                style={{ cursor: "pointer" }}
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </span>
            </div>
          </Form.Group>
        </Col>
        <Col xs={8}>
          <Form.Group className="mb-3">
            <Form.Label>Confirm New Password</Form.Label>
            <div className="position-relative">
              <Form.Control
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Re-enter new password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <span
                className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                style={{ cursor: "pointer" }}
              >
                {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
              </span>
            </div>
          </Form.Group>
        </Col>
      </Row>
      <Col xl={8}>
        <div className="save-btn">
          <Button onClick={handleChangePassword} disabled={isSaving}>
            {isSaving ? "Changing..." : "Change Password"}
          </Button>
        </div>
      </Col>
    </div>
  );
};

export default OwnerChangePassword;
