import React, { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import ownerDocsServices from "../../services/owner-services/owner-documnet";
import { handleErrors } from "../../utils/errorHandler";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faUpload } from "@fortawesome/free-solid-svg-icons";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import FileDropper from "../common/dropper";

const OwnerProfileDocs = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [documentList, setDocumentList] = useState([]);
  const [documentName, setDocumentName] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [documentFile, setDocumentFile] = useState(null);

  const fetchDocumentData = async () => {
    try {
      const response = await ownerDocsServices.getDocumentInfo();
      setDocumentList(response.data.result || []);
    } catch (error) {
      toast.error(handleErrors(error));
    }
  };
  useEffect(() => {
    fetchDocumentData();
  }, []);

  const handleDelete = async (documentId) => {
    try {
      await ownerDocsServices.deleteDocumentInfo(documentId);
      toast.success("Document deleted successfully!");
      setDocumentList((prevList) =>
        prevList.filter((doc) => doc.id !== documentId)
      );
    } catch (error) {
      toast.error(handleErrors(error));
    }
  };

  const confirmDelete = (documentId) => {
    confirmDialog({
      message: "Are you sure you want to delete this document?",
      header: "Delete Confirmation",
      icon: "pi pi-exclamation-triangle",
      acceptClassName: "p-button-danger",
      accept: () => handleDelete(documentId),
      reject: () => toast.info("Deletion canceled."),
    });
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!documentName || !documentFile || documentFile.length === 0) {
      toast.error("Please select a document name and at least one file.");
      return;
    }
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("DocumentName", documentName);
    documentFile.forEach((file) => {
      formData.append("Attachment", file, file.name);
    });
    try {
      console.log("Uploading Document:", documentName);
      documentFile.forEach((file) => console.log("Uploading File:", file.name));
      await ownerDocsServices.uploadDocument(formData);
      toast.success("Document uploaded successfully!");
      fetchDocumentData();
      setDocumentName("");
      setDocumentFile([]);
    } catch (error) {
      console.error("Upload Error:", error.response);
      toast.error(handleErrors(error));
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="owner-documents">
      <div className="my-account-title">
        <h3>Documents</h3>
      </div>
      <ConfirmDialog />
      <Row>
        <Col>
          <Form onSubmit={handleUpload}>
            <Form.Group controlId="supportType" className="mb-3">
              <Form.Label>Documents</Form.Label>
              <Form.Select
                value={documentName}
                onChange={(e) => setDocumentName(e.target.value)}
              >
                <option value="">Select the document</option>
                <option value="Aadhar">Aadhar</option>
                <option value="PAN Card">PAN Card</option>
                <option value="Voter Id">Voter ID</option>
                <option value="Passport">Passport</option>
                <option value="Driving Licence">Driving Licence</option>
              </Form.Select>
            </Form.Group>

            <FileDropper onFileSelect={(file) => setDocumentFile(file)} />
            <Button type="submit" disabled={isSubmitting}>
              {isSubmitting ? "Submitting..." : isEditing ? "Update" : "Submit"}
            </Button>
          </Form>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <div className="renewal-property">
            <DataTable
              value={documentList}
              paginator
              rows={10}
              showGridlines
              stripedRows
            >
              <Column
                header="#"
                body={(rowData, { rowIndex }) => rowIndex + 1}
              />
              <Column field="documentName" header="Document Name" />
              <Column
                body={(rowData) =>
                  rowData.documentLink ? (
                    <img
                      src={rowData.documentLink}
                      alt="Document"
                      style={{
                        width: "50px",
                        height: "40px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSelectedImage(rowData.documentLink);
                        setShowModal(true);
                      }}
                    />
                  ) : (
                    "No Attachment"
                  )
                }
                header="Document Attached"
              />
              <Column
                body={(rowData) =>
                  new Date(rowData.addedOn).toLocaleDateString()
                }
                header="Added On"
              />
              <Column
                body={(rowData) => (
                  <Button
                    variant="link"
                    className="delete-btn"
                    onClick={() => confirmDelete(rowData.id)}
                  >
                    <FontAwesomeIcon className="text-danger" icon={faTrash} />
                  </Button>
                )}
                header="Actions"
              />
            </DataTable>
          </div>
        </Col>
      </Row>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Button
          variant="light"
          className="ms-auto"
          onClick={() => setShowModal(false)}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
        <Modal.Body className="text-center">
          <img
            src={selectedImage}
            alt="Full Document"
            style={{ width: "100%" }}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OwnerProfileDocs;
