import React, { useEffect, useState } from "react";
import {
  faCheckCircle,
  faFileShield,
  faIdCard,
  faInr,
  faLocationDot,
  faLock,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Modal, Row, Spinner, Form } from "react-bootstrap";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import assignedService from "../../services/customer-services/assigned-service";
import { useUser } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { handleErrors } from "../../utils/errorHandler";

const AssignedProperty = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const [propertyData, setPropertyData] = useState(null);
  const [renewalStatusData, setRenewalStatusData] = useState([]);
  const [isShowModal, setShowModal] = useState(false);
  const [selectedDuration, setSelectedDuration] = useState(""); // To store the selected duration

  const personalInfoVerified = user?.userInfo?.profileStatus;
  const FlatMatesVerified = user?.userInfo?.flatMateStatus;
  const kycVerified = user?.userInfo?.kycStatus;
  // const witnessVerified = user?.userInfo?.witnessStatus;
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isDocumentSigned, setIsDocumentSigned] = useState(false);
  const [loadingProperty, setLoadingProperty] = useState(false);
  const [loadingRenewal, setLoadingRenewal] = useState(false);
  const [isOneMonthAway, setIsOneMonthAway] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false); // State for dropdown visibilit
  const toggleDropdown = () => setShowDropdown((prev) => !prev);
  const isAccountVerified =
    personalInfoVerified && FlatMatesVerified && kycVerified;

  const handleCloseModal = () => setShowModal(false);
  const [isIframeLoading, setIsIframeLoading] = useState(true);

  const handleIframeLoad = () => {
    setIsIframeLoading(false);
  };
  // const [formData, setFormData] = useState("");
  // Function to handle the download agreement action
  // const handleExtensionChange = (event) => {
  //   setSelectedExtension(event.target.value); // Update the selected extension duration
  // };
  const durationOptions = Array.from({ length: 11 }, (_, index) => index + 1);
  const handleDownloadAgreement = async () => {
    if (isAccountVerified) {
      try {
        const response = await assignedService.getAssignedService();
        const { documentId } = response.data.result;

        if (documentId) {
          const agreementResponse = await assignedService.getAgreementById(
            documentId
          );
          const signedDocumentUrl =
            agreementResponse.data.result.signedDocumentLink;

          if (signedDocumentUrl) {
            setPdfUrl(signedDocumentUrl);
            setIsDocumentSigned(true);
          } else {
            const unsignedDocumentUrl =
              agreementResponse.data.result.unsignedDocumentLink;
            if (unsignedDocumentUrl) {
              setPdfUrl(unsignedDocumentUrl);
              setIsDocumentSigned(false);
            }
          }
        } else {
          // Fetch unsigned document link if documentId is null
          const esignPreviewResponse = await assignedService.getEsignPreview();
          const unsignedDocumentUrl =
            esignPreviewResponse.data.result.unsignedDocumentLink;

          if (unsignedDocumentUrl) {
            setPdfUrl(unsignedDocumentUrl);
            setIsDocumentSigned(false);
          } else {
            toast.error("Unsigned document link is missing.");
          }
        }
      } catch (error) {
        const errorMessage = handleErrors(error);
        toast.error(errorMessage);
      }
      setShowModal(true);
    } else {
      setShowModal(true);
    }
  };
  const handleExtendAgreement = async () => {
    if (!selectedDuration || !propertyData?.orderGuid) {
      toast.error(
        "Please select a duration and ensure order details are available."
      );
      return;
    }

    try {
      const response = await assignedService.extendAgreement(
        selectedDuration,
        propertyData.orderGuid
      );

      if (response.data.isSuccess) {
        toast.success("Agreement extended successfully.");
        // Refresh the property data or renewal status if needed
        assingedProperty();
        assignedRenewal();
        setShowDropdown(false); // Close dropdown after success
      } else {
        toast.error(response.data.message || "Failed to extend agreement.");
      }
    } catch (error) {
      let errorMessage = handleErrors(error);
      if (error.response) {
        if (error.response.status) {
          errorMessage = error.response.data.message;
        }
      }
      toast.error(errorMessage);
    }
  };

  const assingedProperty = () => {
    setLoadingProperty(true);
    assignedService
      .getAssignedService()
      .then((response) => {
        if (response.data.isSuccess && response.data.result) {
          setPropertyData(response.data.result);
          checkAgreementExpiry(response.data.result.endDate);
        }
      })
      .catch((error) => {
        let errorMessage = handleErrors(error);
        if (error.response) {
          if (error.response.status) {
            errorMessage = error.response.data.message;
          }
        }
        toast.error(errorMessage);
      })
      .finally(() => {
        setLoadingProperty(false);
      });
  };
  const assignedRenewal = () => {
    setLoadingRenewal(true);
    assignedService
      .getAssignedRenewal()
      .then((response) => {
        if (response.data.isSuccess) {
          setRenewalStatusData(response.data.result);
        }
      })
      .catch((error) => {
        let errorMessage = handleErrors(error);
        if (error.response) {
          if (error.response.status) {
            errorMessage = error.response.data.message;
          }
        }
        toast.error(errorMessage);
      })
      .finally(() => {
        setLoadingRenewal(false);
      });
  };

  useEffect(() => {
    assingedProperty();
    assignedRenewal();
  }, []);

  const formatDated = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(dateString)
      .toLocaleDateString("en-GB", options)
      .replace(/ /g, "-");
  };

  const checkAgreementExpiry = (endDate) => {
    const currentDate = new Date();
    const expirationDate = new Date(endDate);

    // Calculate the difference in days
    const timeDifference = expirationDate - currentDate;
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)); // Convert milliseconds to days

    // Check if the difference is exactly 30 days
    if (daysDifference <= 30 && daysDifference >= 0) {
      setIsOneMonthAway(true);
      console.log("Agreement expiring soon:", true);
    } else {
      setIsOneMonthAway(false);
      console.log("Agreement expiring soon:", false);
    }
  };

  const handleSignDocument = async () => {
    if (!propertyData?.documentId) {
      toast.error("Document ID is missing. Cannot proceed with signing.");
      return;
    }

    try {
      const response = await assignedService.sendForEsign(
        propertyData.documentId
      );

      if (response.data.isSuccess) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      let errorMessage = handleErrors(error);
      if (error.response) {
        if (error.response.status) {
          errorMessage = error.response.data.message;
        }
      }
      toast.error(errorMessage);
    }
  };

  return (
    <div className="assigned-property-wrap">
      <div className="my-account-title align-items-start">
        <h2>Assigned Property</h2>

        {propertyData && (
          <div className="download-agreement-wrap">
            <Button
              className="btn-theme-green py-2"
              onClick={handleDownloadAgreement}
            >
              Download Agreement
            </Button>
            <Modal
              className={`download-agreement ${
                isAccountVerified ? "preview-document" : ""
              }`}
              size={isAccountVerified ? "xl" : "lg"}
              show={isShowModal}
              onHide={handleCloseModal}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  {isAccountVerified
                    ? "Download Agreement PDF Preview"
                    : "Download Agreement"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="agreement-wrap">
                  {isAccountVerified ? (
                    <>
                      {pdfUrl ? (
                        <div className="pdf-preview-wrapper">
                          {isIframeLoading && (
                            <div className="loader">
                              <span>Loading...</span>
                            </div>
                          )}
                          <iframe
                            className="pdf-iframe"
                            src={`https://docs.google.com/gview?url=${pdfUrl}&embedded=true#toolbar=0`}
                            frameborder="0"
                            title="PDF Preview"
                            onLoad={handleIframeLoad}
                          ></iframe>
                          <div className="action-buttons">
                            {!isDocumentSigned && (
                              <Button
                                className="btn-theme-green py-2"
                                onClick={handleSignDocument}
                              >
                                Sign the Document
                              </Button>
                            )}
                            {isDocumentSigned && (
                              <Button
                                className="btn-theme-blue py-2"
                                onClick={() => window.open(pdfUrl, "_blank")}
                              >
                                Download PDF
                              </Button>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="alert alert-warning">
                          Unable to fetch the document. Please try again later.
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="verification-status">
                      <ul>
                        <li
                          className={`${
                            personalInfoVerified ? "status-verified" : ""
                          }`}
                        >
                          <div className="wrap">
                            <div className="verified-wrap">
                              <div className="icon">
                                <FontAwesomeIcon icon={faLock} />
                              </div>
                              <div className="text">Account</div>
                            </div>
                          </div>
                        </li>
                        <li
                          className={`${
                            FlatMatesVerified ? "status-verified" : ""
                          }`}
                        >
                          <div className="wrap">
                            <div className="verified-wrap">
                              <div className="icon">
                                <FontAwesomeIcon icon={faUserFriends} />
                              </div>
                              <div className="text">Flatmates</div>
                            </div>
                          </div>
                        </li>
                        <li
                          className={`${kycVerified ? "status-verified" : ""}`}
                        >
                          <div className="wrap">
                            <div className="verified-wrap">
                              <div className="icon">
                                <FontAwesomeIcon icon={faFileShield} />
                              </div>
                              <div className="text">KYC</div>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div className="verification-text">
                        Please <a href="/my-account/profile">Click Here</a> to
                        add your pending details.
                      </div>
                    </div>
                  )}
                </div>
              </Modal.Body>
            </Modal>

            {!isAccountVerified && (
              <div className="agreement-note">
                *To download agreement complete your account details{" "}
                <a href="/my-account/profile">Click Here</a>
              </div>
            )}
          </div>
        )}
      </div>

      {propertyData ? (
        <div className="property-detail-wrap">
          <Row>
            <Col md={4} lg={3}>
              <div className="property-image">
                <img src={propertyData.flatImage} alt={propertyData.flatName} />
              </div>
            </Col>
            <Col md={8} lg={9}>
              <div className="right-details">
                <div className="property-detail">
                  <div className="d-flex d-flex-wrap justify-content-between gap-2">
                    <div className="title">
                      <h2>
                        {propertyData.flatNo}-{propertyData.flatName}
                      </h2>{" "}
                      {/* Changed to flatName */}
                      <div className="address">
                        <FontAwesomeIcon icon={faLocationDot} />{" "}
                        {propertyData.propertyLocation}, {propertyData.city}
                      </div>
                    </div>
                    <div className="review-btn">
                      <a
                        href={`https://www.google.com/maps?q=${propertyData.propertyLat},${propertyData.propertyLong}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button className="btn-theme-blue py-2">
                          Write a Review on Google
                        </Button>
                      </a>
                    </div>
                  </div>
                  <div className="building-type">
                    <span className="title">Building Type:</span>
                    <span className="type">{propertyData.bhkType}</span>{" "}
                    {/* Changed to bhkType */}
                  </div>
                  <div className="rent-meta-details">
                    <ul className="rent-meta-list">
                      <li>
                        <strong>Start Date : </strong>
                        <span>{formatDated(propertyData.startDate)}</span>
                      </li>
                      <li>
                        <strong>End Date : </strong>
                        <span>{formatDated(propertyData.endDate)}</span>
                      </li>
                      <li>
                        <strong className="">Rental Amount : </strong>
                        <span>
                          <FontAwesomeIcon icon={faInr} /> {/* INR Icon */}
                          {propertyData.rentAmount}
                        </span>
                      </li>
                      <li>
                        <strong>Deposit Amount : </strong>
                        <span>
                          <FontAwesomeIcon icon={faInr} /> {/* INR Icon */}
                          {propertyData.deposit} {/* Changed to deposit */}
                        </span>
                      </li>
                    </ul>
                    <div className="view-prop-details">
                      <a
                        href={
                          `/property/` +
                          propertyData.flatUrl +
                          `-` +
                          propertyData.flatId
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Property Details
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div className="no-property-message">
          <h6 className="no-data-found">No Assigned Property Found</h6>
          {loadingProperty && (
            <div className="text-center my-4">
              <Spinner animation="border" variant="primary" /> {/* Loader */}
            </div>
          )}
        </div>
      )}

      <Row>
        <Col md={8} lg={12}>
          {isOneMonthAway && (
            <div className="alert alert-danger mt-2">
              <div className="wrraper-button">
                <div className="agreement-note">
                  *Your current rent agreement is expiring soon. Click here to
                  extend.
                </div>
                <Button className="btn-theme-red py-1" onClick={toggleDropdown}>
                  Extend
                </Button>
              </div>
            </div>
          )}
        </Col>
        {showDropdown && (
          <Col md={8} lg="6">
            <Form.Label>Select monthly duration</Form.Label>
            <Form.Group
              className="wrraper-buttons-duration"
              controlId="duration"
            >
              <Form.Select
                className="select"
                value={selectedDuration}
                onChange={(e) => setSelectedDuration(e.target.value)}
              >
                <option value="">Select Duration</option>
                {durationOptions.map((duration) => (
                  <option key={duration} value={duration}>
                    {duration} Month{duration > 1 ? "s" : ""}
                  </option>
                ))}
              </Form.Select>

              <Button
                className="btn-theme-blue py-1"
                onClick={handleExtendAgreement}
              >
                Submit
              </Button>
            </Form.Group>
          </Col>
        )}
      </Row>
      <Row>
        <Row>
          {propertyData &&
          propertyData.assets &&
          propertyData.assets.length > 0 ? (
            <Col>
              <div className="assigned-assets">
                <div className="my-account-subtitle">
                  <h3>Assigned Assets</h3>
                </div>
                <div className="asset-list">
                  <ul>
                    {propertyData.assets.map((asset, index) => (
                      <li key={index}>
                        <FontAwesomeIcon icon={faCheckCircle} />{" "}
                        {asset.productCount} {asset.productName} -{" "}
                        {asset.brandName}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </Col>
          ) : null}
        </Row>
      </Row>

      <Row>
        <Col>
          <div className="renewal-property">
            <div className="my-account-subtitle">
              <h3>Renewal Status</h3>
            </div>
            <div className="renewal-propery-details">
              <DataTable value={renewalStatusData} showGridlines stripedRows>
                <Column
                  field="startDate"
                  header="Start Date"
                  body={(rowData) => formatDated(rowData.startDate)}
                />
                <Column
                  field="endDate"
                  header="End Date"
                  body={(rowData) => formatDated(rowData.endDate)}
                />
                <Column field="duration" header="Duration" />
                <Column
                  field="rentAmount"
                  header="Rental Amount"
                  body={(rowData) => (
                    <div>
                      <FontAwesomeIcon icon={faInr} /> {/* INR Icon */}
                      {rowData.rentAmount}
                    </div>
                  )}
                />
                <Column
                  field="status"
                  header="Status"
                  body={(rowData) => {
                    let badgeClass = "";
                    let badgeText = "";

                    switch (rowData.status) {
                      case "Expired":
                        badgeClass = "badge-danger";
                        badgeText = "Expired";
                        break;
                      case "Running":
                        badgeClass = "badge-success";
                        badgeText = "Running";
                        break;
                      case "UpComing":
                        badgeClass = "badge-info";
                        badgeText = "UpComing";
                        break;
                      case "Expiring":
                        badgeClass = "badge-warning";
                        badgeText = "Expiring";
                        break;
                      default:
                        badgeClass = "badge-secondary";
                        badgeText = "Unknown";
                    }

                    return (
                      <span className={`badge ${badgeClass}`}>{badgeText}</span>
                    );
                  }}
                />
              </DataTable>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AssignedProperty;
