import React, { createContext, useContext, useState, useEffect } from "react";
import wishlistService from "../../services/wishlist-services";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import personalInfoServices from "../../services/customer-services/profile-personal-info";
import { handleErrors } from "../../utils/errorHandler";
import OwnerInfoServices from "../../services/owner-services/owner-personal-info";
const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    isAuthenticated: false,
    WishList: [],
    userInfo: null,
  });

  const fetchUserProfile = async () => {
    try {
      const response = await personalInfoServices.getProfileInfo();
      setUser((prevUser) => ({
        ...prevUser,
        userInfo: response.data.result,
      }));
    } catch (error) {
      const errorMessage = handleErrors(error);
      toast.error(errorMessage);
    }
  };

  const fetchOwnerProfile = async () => {
    try {
      const response = await OwnerInfoServices.getProfileInfo();
      setUser((prevUser) => ({
        ...prevUser,
        userInfo: response.data.result,
        userRole: "Owner",
      }));
    } catch (error) {
      const errorMessage = handleErrors(error);
      toast.error(errorMessage);
    }
  };

  const updateUserProfile = async (payload, userType = "user") => {
    try {
      if (userType === "owner") {
        await OwnerInfoServices.updateOwnerProfileInfo(payload);
      } else {
        await personalInfoServices.updateProfileInfo(payload);
      }

      setUser((prevUser) => ({
        ...prevUser,
        userInfo: { ...prevUser.userInfo, ...payload },
      }));

      toast.success("Profile updated successfully!");
    } catch (error) {
      const errorMessage = handleErrors(error);
      toast.error(errorMessage);
    }
  };

  const fetchWishlist = async () => {
    try {
      const response = await wishlistService.getWishlist();
      setUser((prevUser) => ({
        ...prevUser,
        WishList: response.data.result,
      }));
    } catch (error) {
      const errorMessage = handleErrors(error);
      toast.error(errorMessage);
    }
  };

  const addToWishlist = async (itemId) => {
    if (!user.isAuthenticated) {
      toast.error("Please log in to add items to your wishlist.");
      return;
    }
    try {
      const response = await wishlistService.postWishlist(itemId);
      setUser((prevUser) => ({
        ...prevUser,
        WishList: [...prevUser.WishList, response.data.result[0]],
      }));
      toast.success("Item added to wishlist!");
    } catch (error) {
      const errorMessage = handleErrors(error);
      toast.error(errorMessage);
    }
  };

  const removeFromWishlist = async (itemId) => {
    if (!user.isAuthenticated) {
      toast.error("Please log in to remove items from your wishlist.");
      return;
    }
    try {
      await wishlistService.deleteWishlist(itemId);
      setUser((prevUser) => ({
        ...prevUser,
        WishList: prevUser.WishList.filter((item) => item.id !== itemId),
      }));
      toast.success("Item removed from wishlist!");
    } catch (error) {
      const errorMessage = handleErrors(error);
      toast.error(errorMessage);
    }
  };

  const setAuthentication = (isAuthenticated) => {
    setUser((prevUser) => ({
      ...prevUser,
      isAuthenticated,
    }));
  };

  const checkAuthentication = () => {
    const accessToken = Cookies.get("accessToken");
    setUser((prevUser) => ({
      ...prevUser,
      isAuthenticated: !!accessToken,
    }));
  };

  useEffect(() => {
    // Check authentication and fetch wishlist if authenticated
    const userRole = Cookies.get("userRole");
    console.log("userRole", userRole);
    checkAuthentication();
    if (user.isAuthenticated && userRole !== "O") {
      fetchWishlist();
      fetchUserProfile();
    }
    if (user.isAuthenticated && userRole === "O") {
      fetchOwnerProfile();
    }
  }, [user.isAuthenticated]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        addToWishlist,
        removeFromWishlist,
        setAuthentication,
        updateUserProfile,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};
