import { faPlus, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { validateFlatmateForm } from "../../utils/validation/validate-flatmate-form";
import flatmateServices from "../../services/customer-services/flatmate-service";
import { handleErrors } from "../../utils/errorHandler";
import { toast } from "react-toastify";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";

const FlatMateDetails = () => {
  const [phoneNumberValue, setPhoneNumberValue] = useState();
  const [showAddForm, setShowAddForm] = useState(false);
  const [editingFlatmateId, setEditingFlatmateId] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  // const [isChecked, setIsChecked] = useState(false);
  const [includeInAgreement, setIncludeInAgreement] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    gender: "",
    relationship: "",
    age: "",
    adharPanNumber: "",
    documentType: "Adhar",
  });
  const [errors, setErrors] = useState({});
  const [flatmateTableData, setFlatmateTableData] = useState([]);

  useEffect(() => {
    const fetchFlatmateData = async () => {
      try {
        const response = await flatmateServices.getflatmate();
        setFlatmateTableData(response.data.result);
      } catch (error) {
        const errorMessage = handleErrors(error);
        toast.error(errorMessage);
      }
    };

    fetchFlatmateData();
  }, []);

  // Handle form field change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async () => {
    setIsButtonDisabled(true);
    const validationErrors = validateFlatmateForm({
      ...formData,
      contactNumber: phoneNumberValue,
    });

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setIsButtonDisabled(false);
    } else {
      try {
        const payload = {
          id: editingFlatmateId,
          firstName: formData.firstName,
          lastName: formData.lastName,
          emailAddress: formData.email,
          contactNo: phoneNumberValue,
          relationship: formData.relationship,
          gender: formData.gender,
          age: formData.age,
          idType: formData.documentType,
          idNo: formData.adharPanNumber,
          includeInAgreement: includeInAgreement ? "Yes" : "No",
        };

        if (editingFlatmateId) {
          const response = await flatmateServices.updateFlatmate(payload);
          setFlatmateTableData((prevData) =>
            prevData.map((flatmate) =>
              flatmate.id === editingFlatmateId
                ? { ...flatmate, ...payload }
                : flatmate
            )
          );
          toast.success("Flatmate updated successfully.");
        } else {
          const response = await flatmateServices.postflatmate(payload);
          const fetchFlatmateData = async () => {
            const updatedResponse = await flatmateServices.getflatmate();
            setFlatmateTableData(updatedResponse.data.result);
          };
          await fetchFlatmateData();

          toast.success("Flatmate added successfully.");
        }

        resetForm();
      } catch (error) {
        let errorMessage = handleErrors(error);
        if (error.response && error.response.status === 409) {
          errorMessage =
            error.response.data.message ||
            "Conflict: Contact No or Email Address already exist.";
        }

        toast.error(errorMessage);
      } finally {
        setIsButtonDisabled(false);
      }
    }
  };

  const resetForm = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      contactNumber: "",
      gender: "",
      relationship: "",
      age: "",
      adharPanNumber: "",
      documentType: "Adhar",
    });
    setPhoneNumberValue("");
    setErrors({});
    setEditingFlatmateId(null);
    setShowAddForm(false);
  };

  const renderIdNumber = (rowData) => {
    if (!rowData.idNo) return "--";
    return rowData.idType === "Adhar" ? rowData.idNo : "--";
  };

  const renderPanNumber = (rowData) => {
    if (!rowData.idNo) return "--";
    return rowData.idType === "Pan" ? rowData.idNo : "--";
  };

  const handleDelete = async (id) => {
    setIsButtonDisabled(true);
    try {
      await flatmateServices.deleteflatmate(id);
      setFlatmateTableData(
        flatmateTableData.filter((flatmate) => flatmate.id !== id)
      );
      toast.success("Flatmate deleted successfully.");
    } catch (error) {
      let errorMessage = handleErrors(error);
      if (error.response) {
        if (error.response.status === 500) {
          errorMessage = error.response.data.message;
        }
      }
      toast.error(errorMessage);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const confirmDelete = (id) => {
    if (confirmDialog) {
      confirmDialog({
        message: "Are you sure you want to delete this flatmate?",
        header: "Confirmation",
        icon: "pi pi-info-circle",
        accept: () => handleDelete(id),
        reject: () => toast.info("Deletion canceled."),
      });
    } else {
    }
  };

  const handleEdit = async (flatmate) => {
    setEditingFlatmateId(flatmate.id);
    setFormData({
      firstName: flatmate.firstName,
      lastName: flatmate.lastName,
      email: flatmate.emailAddress,
      gender: flatmate.gender,
      relationship: flatmate.relationship,
      age: flatmate.age,
      adharPanNumber: flatmate.idNo,
      documentType: flatmate.idType,
    });
    setPhoneNumberValue(flatmate.contactNo);
    setIncludeInAgreement(flatmate.includeInAgreement === "Yes");

    setShowAddForm(true);
  };

  return (
    <div className="flatmate-details-wrap">
      <ConfirmDialog />
      <div className="my-account-title">
        <div className="title-wrap">
          <h2>Flatmate Details</h2>
        </div>
        <div className="add-btn">
          {!showAddForm && !editingFlatmateId && (
            <Button type="button" onClick={() => setShowAddForm(true)}>
              <FontAwesomeIcon className="me-2" icon={faPlus} />
              Add Flatmate Detail
            </Button>
          )}
        </div>
      </div>
      {showAddForm ? (
        <div className="profile-info-wrap">
          <Row>
            <Col xs={12} xl={4} lg={6} sm={6}>
              <Form.Label>First Name</Form.Label>
              <Form.Group className="form-group" controlId="first-name">
                <Form.Control
                  type="text"
                  placeholder="Your Name*"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  disabled={isButtonDisabled}
                />
                {errors.firstName && (
                  <span className="text-danger">{errors.firstName}</span>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} xl={4} lg={6} sm={6}>
              <Form.Label>Last Name</Form.Label>
              <Form.Group className="form-group" controlId="last-name">
                <Form.Control
                  type="text"
                  placeholder="Your Name*"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  disabled={isButtonDisabled}
                />
                {errors.lastName && (
                  <span className="text-danger">{errors.lastName}</span>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} xl={4} lg={6} sm={6}>
              <Form.Label>Email</Form.Label>
              <Form.Group className="form-group" controlId="email">
                <Form.Control
                  type="email"
                  placeholder="abc@xyz.com*"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  disabled={isButtonDisabled}
                />
                {errors.email && (
                  <span className="text-danger">{errors.email}</span>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} xl={4} lg={6} sm={6}>
              <Form.Label>Contact Number</Form.Label>
              <Form.Group className="form-group" controlId="phone-number">
                <PhoneInput
                  international
                  type="phone"
                  maxLength="15"
                  defaultCountry="IN"
                  value={phoneNumberValue}
                  onChange={setPhoneNumberValue}
                />
                {errors.contactNumber && (
                  <span className="text-danger">{errors.contactNumber}</span>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} xl={4} lg={6} sm={6}>
              <Form.Group className="form-group" controlId="gender">
                <Form.Label>Gender</Form.Label>
                <Form.Select
                  className=" form-control"
                  name="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Form.Select>
                {errors.gender && (
                  <span className="text-danger">{errors.gender}</span>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} xl={4} lg={6} sm={6}>
              <Form.Label>Relationship</Form.Label>
              <Form.Group className="form-group" controlId="relationship">
                <Form.Control
                  type="text"
                  name="relationship"
                  value={formData.relationship}
                  onChange={handleInputChange}
                />
                {errors.relationship && (
                  <span className="text-danger">{errors.relationship}</span>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} xl={4} lg={6} sm={6}>
              <Form.Label>Age</Form.Label>
              <Form.Group className="form-group" controlId="age">
                <Form.Control
                  type="number"
                  name="age"
                  min="1"
                  max="99"
                  value={formData.age}
                  onChange={handleInputChange}
                />
                {errors.age && (
                  <span className="text-danger">{errors.age}</span>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} xl={4} lg={6} sm={6}>
              <Form.Group className="form-group" controlId="documentType">
                <Form.Label>Document Type</Form.Label>
                <Form.Select
                  className=" form-control"
                  name="documentType"
                  value={formData.documentType}
                  onChange={handleInputChange}
                >
                  <option value="Adhar">Aadhar</option>
                  <option value="Pan">Pan</option>
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={12} xl={4} lg={6} sm={6}>
              <Form.Label>{formData.documentType} Number</Form.Label>
              <Form.Group className="form-group" controlId="adharPanNumber">
                <Form.Control
                  type="text"
                  name="adharPanNumber"
                  value={formData.adharPanNumber}
                  onChange={(e) => {
                    const maxLength =
                      formData.documentType === "Adhar" ? 12 : 10;
                    const newValue = e.target.value.slice(0, maxLength); // Truncate to maxLength
                    setFormData({ ...formData, adharPanNumber: newValue });
                  }}
                  placeholder={`Enter your ${formData.documentType} number`}
                />
                {errors.adharPanNumber && (
                  <span className="text-danger">{errors.adharPanNumber}</span>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} xl={4} lg={6} sm={6}>
              <Form.Group className="form-group" controlId="isChecked">
                <Form.Check
                  type="checkbox"
                  label="Include me in agreements."
                  checked={includeInAgreement}
                  onChange={(e) => setIncludeInAgreement(e.target.checked)}
                />
              </Form.Group>
            </Col>

            <Col xs={12} lg={12}>
              <div className="buttons-wrap">
                <Button
                  className="btn-secondary py-2"
                  onClick={resetForm}
                  disabled={isButtonDisabled}
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={isButtonDisabled}
                >
                  {editingFlatmateId ? "Update Flatmate" : "Add Flatmate"}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        ""
      )}
      <div className="flatmate-details-list">
        <DataTable value={flatmateTableData} showGridlines stripedRows>
          <Column
            field="firstName"
            header="Name"
            style={{ minWidth: "150px" }}
            body={(colData) => `${colData.firstName} ${colData.lastName}`}
          />
          <Column field="emailAddress" header="Email" />
          <Column field="contactNo" header="Contact No" />
          <Column field="age" header="Age" />
          <Column field="gender" header="Gender" />
          <Column
            field="includeInAgreement"
            header="Include in Agreement"
            body={(rowData) =>
              rowData.includeInAgreement === "Yes" ? "Yes" : "No"
            }
          />

          <Column field="relationship" header="Relationship" />
          <Column field="idType" header="Document Type" />
          <Column
            header="Document Number"
            body={(rowData) => {
              if (rowData.idType === "Adhar") {
                return rowData.idNo ? rowData.idNo : "--";
              } else if (rowData.idType === "Pan") {
                return rowData.idNo ? rowData.idNo : "--";
              }
              return "--";
            }}
          />
          <Column
            header="Actions"
            body={(rowData) => (
              <div className="action-button-wrraper">
                <Button
                  variant="link"
                  onClick={() => handleEdit(rowData)}
                  disabled={isButtonDisabled}
                  className="me-2 edit-btn"
                >
                  <FontAwesomeIcon icon={faEdit} />
                </Button>
                <Button
                  variant="link"
                  onClick={() => confirmDelete(rowData.id)}
                  disabled={isButtonDisabled}
                  className="delete-btn"
                >
                  <FontAwesomeIcon className="text-danger" icon={faTrash} />
                </Button>
              </div>
            )}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default FlatMateDetails;
