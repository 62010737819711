// validation.js

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!email) {
    return "Email is required.";
  } else if (!emailRegex.test(email)) {
    return "Please enter a valid email address.";
  } else if (email.length > 50 || email.length < 1) {
    return "Email must be between 1 and 50 characters.";
  }
  return "";
};

export const validatePassword = (password) => {
  const passwordRegex = /(?=.*\d)(?=.*[@#$%^&+=_])(?=.*[A-Za-z]).{6,}/;
  if (!password) {
    return "Password is required.";
  } else if (!passwordRegex.test(password)) {
    return "Password must be at least 6 characters long and contain at least 1 letter, 1 number, and 1 special character.";
  } else if (password.length > 30) {
    return "Password cannot exceed 30 characters.";
  }
  return "";
};
