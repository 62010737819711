import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { validateWitnessForm } from "../../utils/validation/validate-witness-list";
import witnesslistServices from "../../services/customer-services/witnesslist-service";
import { toast } from "react-toastify";
import { handleErrors } from "../../utils/errorHandler"; // Adjust the path as necessary

const WitnessList = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    aadharNumber: "",
  });
  const [showAddForm, setShowAddForm] = useState(false);
  const [errors, setErrors] = useState({});
  const [witnessData, setWitnessData] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const fetchWitnessList = async () => {
      try {
        const response = await witnesslistServices.getWitnessList();
        if (response?.data?.isSuccess) {
          setWitnessData(response.data.result);
        } else {
          toast.error("Failed to fetch witness data.");
        }
      } catch (error) {
        let errorMessage = handleErrors(error);
        if (error.response) {
          if (error.response.status === 500) {
            errorMessage = error.response.data.message;
          }
        }
        toast.error(errorMessage);
      }
    };

    fetchWitnessList();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    const validationErrors = validateWitnessForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setIsButtonDisabled(true);

    const payload = {
      wName: formData.name,
      wEmail: formData.email,
      wContact: formData.phoneNumber,
      wAadhar: formData.aadharNumber,
    };

    try {
      await witnesslistServices.postWitnesslist(payload); // Submits the form
      const response = await witnesslistServices.getWitnessList(); // Refreshes the witness list
      if (response?.data?.isSuccess) {
        setWitnessData(response.data.result);
      }
      // Reset the form and close it
      setFormData({
        name: "",
        email: "",
        phoneNumber: "",
        aadharNumber: "",
      });
      setShowAddForm(false);
      setErrors({});
      toast.success("Witness details saved successfully!");
    } catch (error) {
      let errorMessage = handleErrors(error);
      if (error.response && error.response.status === 409) {
        errorMessage =
          error.response.data.message ||
          "information already exists. Please check and try again.";
      }
      toast.error(errorMessage);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  return (
    <div className="witness-detail-wrap">
      {/* <ToastContainer /> */}
      <div className="my-account-title">
        <div className="title-wrap">
          <h2>Witness Details</h2>
        </div>
        <div className="add-btn">
          <Button type="button" onClick={() => setShowAddForm(!showAddForm)}>
            <FontAwesomeIcon className="me-2" icon={faPlus} />
            Add Witness Detail
          </Button>
        </div>
      </div>
      {showAddForm && (
        <div className="profile-info-wrap">
          <Row>
            <Col xs={12} xl={4} lg={6} sm={6}>
              <Form.Label>Witness Full Name</Form.Label>
              <Form.Group className="form-group" controlId="name">
                <Form.Control
                  type="text"
                  placeholder="Your Name*"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                />
                {errors.name && (
                  <span className="text-danger">{errors.name}</span>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} xl={4} lg={6} sm={6}>
              <Form.Label>Email</Form.Label>
              <Form.Group className="form-group" controlId="email">
                <Form.Control
                  type="email"
                  placeholder="abc@xyz.com*"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
                {errors.email && (
                  <span className="text-danger">{errors.email}</span>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} xl={4} lg={6} sm={6}>
              <Form.Label>Contact Number</Form.Label>
              <Form.Group className="form-group" controlId="phoneNumber">
                <PhoneInput
                  international
                  defaultCountry="IN"
                  maxLength="15"
                  value={formData.phoneNumber}
                  onChange={(value) =>
                    setFormData({ ...formData, phoneNumber: value })
                  }
                />
                {errors.phoneNumber && (
                  <span className="text-danger">{errors.phoneNumber}</span>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} xl={4} lg={6} sm={6}>
              <Form.Label>Aadhar Card Number</Form.Label>
              <Form.Group className="form-group" controlId="aadharNumber">
                <Form.Control
                  type="number"
                  placeholder="1234 5678 9101"
                  name="aadharNumber"
                  value={formData.aadharNumber}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (input.length <= 12) {
                      handleInputChange(e);
                    }
                  }}
                />

                {errors.aadharNumber && (
                  <span className="text-danger">{errors.aadharNumber}</span>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} lg={12}>
              <div className="buttons-wrap">
                <Button
                  className="btn-secondary py-2"
                  onClick={() => {
                    setShowAddForm(false);
                    setFormData({
                      name: "",
                      email: "",
                      phoneNumber: "",
                      aadharNumber: "",
                    });
                    setErrors({});
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="py-2"
                  onClick={handleSubmit}
                  disabled={isButtonDisabled}
                >
                  Save Details
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      )}
      <div className="flatmate-details-list">
        {Array.isArray(witnessData) && (
          <DataTable
            paginator
            rows={10}
            value={witnessData}
            showGridlines
            stripedRows
          >
            <Column field="wName" header="Name" />
            <Column field="wEmail" header="Email" />
            <Column field="wContact" header="Contact No" />
            <Column field="wAadhar" header="Aadhar Number" />
          </DataTable>
        )}
      </div>
    </div>
  );
};

export default WitnessList;
