import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import OwnerInfo from "./owner-info";
// import FlatMateDetails from "./flatmate-details";
// import MyKyc from "./my-kyc";
// import WitnessList from "./witnessList";
// import { ToastContainer } from "react-toastify";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../context/UserContext";
// import OwnerAggerment from "./owner-profile-agreement";

const OwnerProfile = () => {
  const { user } = useUser(); // Access user context directly

  // const renderUserGuideNote = () => {
  //   const nationality = user?.userInfo?.nationality;

  //   if (!nationality) return null;

  //   switch (nationality) {
  //     case "Indian":
  //       return (
  //         <div className="user-guide-note">
  //           <div className="content-wrap">
  //             <ul>
  //               <li>
  //                 <div className="wrap">
  //                   <FontAwesomeIcon icon={faCheck} />
  //                   <span>Pan / Aadhaar / Driving License - Please verify</span>
  //                 </div>
  //               </li>
  //               <li>
  //                 <div className="wrap">
  //                   <FontAwesomeIcon icon={faCheck} />
  //                   <span>
  //                     Upload your document - Aadhar/Passport Front,
  //                     Aadhar/Passport Back, PAN Card{" "}
  //                   </span>
  //                 </div>
  //               </li>
  //             </ul>
  //           </div>
  //         </div>
  //       );

  //     case "NRI":
  //       return (
  //         <div className="user-guide-note">
  //           <div className="content-wrap">
  //             <ul>
  //               <li>
  //                 <div className="wrap">
  //                   <FontAwesomeIcon icon={faCheck} />
  //                   <span>Passport Verification Required</span>
  //                 </div>
  //                 <div className="wrap">
  //                   <FontAwesomeIcon icon={faCheck} />
  //                   <span>Visa Verification Required</span>
  //                 </div>
  //                 <div className="wrap">
  //                   <FontAwesomeIcon icon={faCheck} />
  //                   <span>
  //                     Aadhaar/Passport Softcopy (front & back) and PAN card
  //                     softcopy verification required
  //                   </span>
  //                 </div>
  //               </li>
  //             </ul>
  //           </div>
  //         </div>
  //       );

  //     case "Foreigner":
  //       return (
  //         <div className="user-guide-note">
  //           <div className="content-wrap">
  //             <ul>
  //               <li>
  //                 <div className="wrap">
  //                   <FontAwesomeIcon icon={faCheck} />
  //                   <span>Passport verification required</span>
  //                 </div>
  //                 <div className="wrap">
  //                   <FontAwesomeIcon icon={faCheck} />
  //                   <span>Visa validity verification required</span>
  //                 </div>
  //                 <div className="wrap">
  //                   <FontAwesomeIcon icon={faCheck} />
  //                   <span>
  //                     Upload your document - Aadhaar/Passport Front,
  //                     Aadhaar/Passport Back{" "}
  //                   </span>
  //                 </div>
  //               </li>
  //             </ul>
  //           </div>
  //         </div>
  //       );

  //     default:
  //       return null;
  //   }
  // };


  return (
    <>
      <div className="my-account-title tab-show">
        <h2>Owner Profile</h2>
      </div>
      <Tabs defaultActiveKey="details" id="personal-info" className="mb-3">
        <Tab eventKey="details" title="Personal Details">
          {/* <div className="user-guide-note">
            <div className="title">
              <h4>Add Mandatory Details to get your Rent Agreement</h4>
            </div>
            <div className="content-wrap">
              <ul>
                <li>
                  <div className="wrap">
                    <FontAwesomeIcon icon={faCheck} />
                    <span>
                      Your Personal information like First Name, Last Name,
                      E-Mail, Contact No, Gender, DOB, and your permanent
                      address.
                    </span>
                  </div>
                </li>
                <li>
                  <div className="wrap">
                    <FontAwesomeIcon icon={faCheck} />
                    <span>Also Add your flatmate details (if any).</span>
                  </div>
                </li>
                <li>
                  <div className="wrap">
                    <FontAwesomeIcon icon={faCheck} />
                    <span>Upload your ID proofs.</span>
                  </div>
                </li>
                <li>
                  <div className="wrap">
                    <FontAwesomeIcon icon={faCheck} />
                    <span>My KYC:</span>
                  </div>
                  <ul className="mandate-list">
                    <li>At least one ID should be verified.</li>
                    <li>
                      For Indian tenants Aadhaar/Driving License Number Required
                      along with their softcopy.
                    </li>
                    <li>
                      For International tenants Passport Required along with
                      their softcopy.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div> */}
          <OwnerInfo />
        </Tab>
        {/* <Tab eventKey="flatmate-details" title="Owner Agrement">
          <OwnerAggerment />
        </Tab> */}
        {/* <Tab eventKey="my-kyc" title="My KYC">
          {renderUserGuideNote()}
          {renderAlertMessage()}
          <MyKyc />
        </Tab> */}
        {/* <Tab eventKey="my-witness" title="My Witness">
          <WitnessList />
        </Tab> */}
      </Tabs>
    </>
  );
};

export default OwnerProfile;
