import React, { useState } from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  validateEmail,
  validatePassword,
} from "../utils/validation/owner-validation";
import ownerInfoLogin from "../services/owner-services/owner-login-services";
import "react-phone-number-input/style.css";
import "../styles/login-register.css";
import { useUser } from "../components/context/UserContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { handleErrors } from "../utils/errorHandler";
import Cookies from "js-cookie";

const OwnerLogin = () => {
  const [emailAddress, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [apiError, setApiError] = useState("");
  const { setAuthentication } = useUser();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  const handleLogin = async () => {
    const emailError = validateEmail(emailAddress);
    const passwordError = validatePassword(password);

    if (emailError || passwordError) {
      setErrors({ email: emailError, password: passwordError });
      return;
    }

    setErrors({});
    setIsSubmitting(true);

    try {
      const response = await ownerInfoLogin.ownerLogin({
        emailAddress,
        password,
      });

      console.log("response", response);

      if (response.isSuccess) {
        console.log("Success");
        const accessToken = response?.isSuccess;
        if (accessToken) {
          setAuthentication(!!accessToken);
          navigate("/owner-account/ownerprofile");
        } else {
          setAuthentication(null);
        }
      } else {
        setApiError(
          response.message || "Login failed. Please check your credentials."
        );
      }
    } catch (error) {
      let errorMessage = handleErrors(error);
      if (error.response) {
        if (error.response.status === 404) {
          errorMessage = error.response.data.message;
        } else if (error.response.status === 500) {
          errorMessage = error.response.data.message;
        }
      }
      setApiError(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="login-wrapper">
      <div className="loginbox">
        <div className="login-auth">
          <div className="login-auth-wrap">
            <h1>Owner Login.</h1>
            <Form>
              {apiError && <Alert variant="danger">{apiError}</Alert>}
              <Form.Group className="mb-3">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter your email"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                  onKeyDown={(e) => e.key === "Enter" && handleLogin()}
                />

                {errors.email && (
                  <div className="text-danger">{errors.email}</div>
                )}
              </Form.Group>
              <Form.Group className="mb-3 position-relative">
                <Form.Label>Password</Form.Label>
                <div className="position-relative">
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={(e) => e.key === "Enter" && handleLogin()}
                  />

                  <span
                    className="position-absolute top-50 end-0 translate-middle-y me-3 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                    style={{ cursor: "pointer" }}
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </div>
                {errors.password && (
                  <div className="text-danger">{errors.password}</div>
                )}
              </Form.Group>
              <Button
                type="button"
                className="btn btn-theme-blue w-100"
                onClick={handleLogin}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Logging in..." : "Login"}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OwnerLogin;
