import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useCallback } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import blogService from "../services/blogService";
import { ProgressSpinner } from "primereact/progressspinner";
import propertyService from "../services/propertyService";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper/modules";
// import PropertyCard from "../components/common/property-card";
import { handleErrors } from "../utils/errorHandler";
import { ErrorHandlingDesign } from "../components/common/errorHandlingDesign";
import { Helmet } from "react-helmet-async";

const BlogPost = () => {
  const dataUrl = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [blogData, setBlogData] = useState({});
  const [BlogId, setBlogId] = useState();
  const [BlogUrl, setBlogUrl] = useState();
  // const [errorFeature, setErrorFeature] = useState(null);
  const [propertyList, setPropertyList] = useState([]);

  const pathname = dataUrl.slug;
  const lastSegment = pathname.split("-");
  const extractId = lastSegment.pop();

  const extractUrl = lastSegment.join("-");
  // setBlogId(extractId);
  // setBlogUrl(extractUrl);

  // useEffect(() => {

  // }, [dataUrl]);

  const fetchBlogDetail = useCallback(async () => {
    const payload = {
      blogId: extractId,
      blogURL: extractUrl,
    };

    setLoading(true);
    try {
      const response = await blogService.getBlogDetail(payload);
      setBlogData(response.data.result);
    } catch (error) {
      const errorMessage = handleErrors(error);
      setError(errorMessage);
    } finally {
      setLoading(false);
    }
  }, [BlogId, BlogUrl]);

  useEffect(() => {
    fetchBlogDetail();
  }, [fetchBlogDetail, dataUrl]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await propertyService.getFeaturedProperty();
        setPropertyList(response.data.result || []);
      } catch (error) {
        const errorMessage = handleErrors(error);
        setError(errorMessage);
      }
    };

    fetchProperties();
  }, []);

  return (
    <>
      <Helmet>
        <title>{blogData.pageTitle}</title>
        <meta name="description" content={blogData.metaDesc} />
        <meta name="keywords" content={blogData.metaKeys} />
      </Helmet>

      <section className="custom-breadcrumb">
        <Container>
          <Row>
            <Col sm={12}>
              <ul className="bread-crumb-list">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/blogs">Blogs</Link>
                </li>
                <li>
                  <Link to="#">{blogData.blogName}</Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="blog-inner-section section-padding">
        <Container>
          <Row>
            <Col lg={12} className="mx-auto">
              {error ? (
                <ErrorHandlingDesign errorMessage={error} />
              ) : loading ? (
                <ProgressSpinner
                  style={{ width: "50px", height: "50px" }}
                  strokeWidth="3"
                  fill="var(--surface-ground)"
                  animationDuration=".5s"
                />
              ) : (
                <div className="blog-banner">
                  <div className="blog-banner-contenthead">
                    <h1>{blogData.blogName}</h1>
                    <div className="meta-tag-wrap">
                      <div className="blog-date">
                        Updated on :{" "}
                        <span>{formatDate(blogData.postedOn)}</span>
                      </div>
                      <div className="tags-wrap">
                        <div className="item">{blogData.blogCategory}</div>
                      </div>
                    </div>
                  </div>
                  <div className="blog-banner-img">
                    <img src={blogData.bigImage} alt="img" />
                  </div>
                  <div className="banner-content-wrapper">
                    <Row>
                      <Col xl={9} lg={8}>
                        <div className="blog-banner-content">
                          <div
                            className="blog-content-inner"
                            dangerouslySetInnerHTML={{
                              __html: blogData.blogDesc,
                            }}
                          />
                        </div>
                      </Col>
                      <Col xl={3} lg={4}>
                        <div className="looking-for-wrap flex-column">
                          <div className="become-tenant">
                            <div className="wrap w-100">
                              <div className="title">
                                Want to Rent out your property ?
                              </div>
                              <Link to="/list-your-property" target="_blank">
                                List Your Property{" "}
                                <FontAwesomeIcon
                                  icon={faArrowRight}
                                  className="ms-2"
                                />
                              </Link>
                            </div>
                          </div>
                          <div className="become-owner">
                            <div className="wrap w-100">
                              <div className="title">
                                Looking to Rent Property ?
                              </div>
                              <Link to="/properties" target="_blank">
                                Search Properties{" "}
                                <FontAwesomeIcon
                                  icon={faArrowRight}
                                  className="ms-2"
                                />
                              </Link>
                            </div>
                          </div>
                        </div>
                        {/* <div className="blog-properties">
                          <h3>Our Properties in Bangalore</h3>
                          <Swiper
                            navigation={true}
                            modules={[Navigation]}
                            className="blog-prop-slider"
                            slidesPerView={1}
                            pagination={{
                              clickable: true,
                            }}
                          >
                            {propertyList.map((property) => {
                              return (
                                <SwiperSlide key={`${property.id}`}>
                                  <PropertyCard
                                    property={property}
                                    showMetaDetails={false}
                                    hideWishList={false}
                                  />
                                </SwiperSlide>
                              );
                            })}
                          </Swiper>
                        </div> */}
                      </Col>
                    </Row>
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default BlogPost;
