import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { handleErrors } from "../../utils/errorHandler";
import bankDetailsServices from "../../services/owner-services/owner-bank";
import { validateBankDetails } from "../../utils/validation/owner-bank-validation";

const OwnerBankDetails = () => {
  const [formData, setFormData] = useState({
    bankName: "",
    accountNo: "",
    ifscCode: "",
    accountName: "",
  });

  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  async function fetchBankDetails() {
    try {
      const response = await bankDetailsServices.getBankDetails();
      if (response.data.result) {
        const data = response.data.result;
        setFormData({
          bankName: data.bankName || "",
          accountNo: data.accountNo || "",
          ifscCode: data.ifscCode || "",
          accountName: data.accountName || "",
        });
        setIsUpdating(true);
      }
    } catch (error) {
      toast.error(handleErrors(error));
    }
  }

  useEffect(() => {
    fetchBankDetails();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    const formErrors = validateBankDetails(formData);
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      try {
        setIsSaving(true);
        setIsButtonDisabled(true);

        if (isUpdating) {
          await bankDetailsServices.updateBankDetails(formData);
          toast.success("Bank details updated successfully!");
        } else {
          await bankDetailsServices.postBankDetails(formData);
          toast.success("Bank details added successfully!");
        }

        fetchBankDetails();
      } catch (error) {
        toast.error(handleErrors(error));
      } finally {
        setIsSaving(false);
        setIsButtonDisabled(false);
      }
    }
  };

  return (
    <div className="personal-info">
      <div className="my-account-title">
        <h3>Bank Details</h3>
      </div>
      <div className="profile-info-wrap">
        <Row>
          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Group className="form-group">
              <Form.Label>Bank Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Bank Name*"
                name="bankName"
                value={formData.bankName}
                onChange={handleInputChange}
                isInvalid={!!errors.bankName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.bankName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Label>Account Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Account Number*"
              name="accountNo"
              value={formData.accountNo}
              onChange={handleInputChange}
              isInvalid={!!errors.accountNo}
            />
            <Form.Control.Feedback type="invalid">
              {errors.accountNo}
            </Form.Control.Feedback>
          </Col>
          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Label>IFSC Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="IFSC Code*"
              name="ifscCode"
              value={formData.ifscCode}
              onChange={handleInputChange}
              isInvalid={!!errors.ifscCode}
            />
            <Form.Control.Feedback type="invalid">
              {errors.ifscCode}
            </Form.Control.Feedback>
          </Col>
          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Label>Account Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Account Name*"
              name="accountName"
              value={formData.accountName}
              onChange={handleInputChange}
              isInvalid={!!errors.accountName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.accountName}
            </Form.Control.Feedback>
          </Col>
        </Row>
      </div>
      <div className="save-btn">
        <Button
          type="button"
          onClick={handleSubmit}
          disabled={isButtonDisabled}
        >
          {isSaving
            ? "Saving..."
            : isUpdating
            ? "Update Bank Details"
            : "Save Bank Details"}
        </Button>
      </div>
    </div>
  );
};

export default OwnerBankDetails;
