import { faInr } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import bookingService from "../services/bookingService";
import { useNavigate } from "react-router-dom";
import { handleErrors } from "../utils/errorHandler";
import { ErrorHandlingDesign } from "../components/common/errorHandlingDesign";
import { Helmet } from "react-helmet-async";
import { CircularProgress } from "@mui/material";

const Checkout = () => {
  const location = useLocation();
  const { orderId } = location.state || {};
  const [basicDetails, setBasicDetails] = useState([]);
  const [isBasicDetailsLoaded, setIsBasicDetailsLoaded] = useState(false);
  const [error, setErrors] = useState(null);
  const navigate = useNavigate();
  const [isPaynowClicked, setIsPaynowClicked] = useState(false);
  const [paymentStatusLoader, setPaymentStatusLoader] = useState(false);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return date.toLocaleDateString("en-US", options).replace(",", "");
  };

  useEffect(() => {
    const fetchBasicDetails = async () => {
      try {
        const basicDetails = await bookingService.getBookingBasicDetails(
          orderId
        );
        setBasicDetails(basicDetails.data.result);
        setIsBasicDetailsLoaded(true);
      } catch (error) {
        const errorMessage = handleErrors(error);
        setErrors(errorMessage);
        setIsBasicDetailsLoaded(false);
      }
    };

    fetchBasicDetails();
  }, [orderId]);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const handlePayment = async () => {
    setIsPaynowClicked(true);
    try {
      const paymentRequest = await bookingService.getPaymentRequestDetails(
        orderId
      );
      const paymentRequestDetails = paymentRequest.data.result;
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }

      const options = {
        // key: "rzp_test_obKqWxOCW5u38m",
        key: "rzp_live_IpdyqNvD8KrUW9",
        amount: paymentRequestDetails.paidAmount,
        currency: "INR",
        name: "SoWeRent",
        image: "https://sowerent.com/assets/images/logo/sowerent-logo-blue.png",
        description: "Booking Payment",
        order_id: paymentRequestDetails.orderPaymentId,
        handler: async function (response) {
          setPaymentStatusLoader(true);

          const data = {
            OrderGuid: orderId,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_order_id: response.razorpay_order_id,
            razorpay_signature: response.razorpay_signature,
          };
          try {
            const result = await bookingService.getPaymentSuccessDetails(data);
            if (result.data.result.payment_status === "Success") {
              setPaymentStatusLoader(false);
              navigate("/booking-confirmation", {
                state: {
                  orderId: orderId,
                  paymentId: response.razorpay_order_id,
                },
              });
            } else {
              navigate("/booking-failure");
            }
          } catch (error) {
            navigate("/booking-failure");
          }
        },

        prefill: {
          name: paymentRequestDetails.userName,
          email: paymentRequestDetails.emailAddress,
          contact: paymentRequestDetails.contactNo,
        },
        notes: {
          address: paymentRequestDetails.propertyAddress,
        },
        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
      setIsPaynowClicked(false);
    } catch (error) {
      const errorMessage = handleErrors(error);
      setErrors(errorMessage);
    }
  };

  return (
    <>
      <Helmet>
        <title>Checkout | Sowerent</title>
        <meta
          name="description"
          content="Complete your purchase and finalize your booking on Sowerent. Enter shipping and payment details."
        />
      </Helmet>
      <section className="section-padding booking-banner-text residence-banner">
        <Container className="booking-container">
          <Row>
            {!paymentStatusLoader ? (
              <Col>
                {error ? (
                  <ErrorHandlingDesign errorMessage={error} />
                ) : (
                  <div className="rental-info">
                    <div className="left-col">
                      <div className="userform-details">
                        <div className="bookig-details">
                          <h2 className="inner-title">Booking Preview</h2>
                          <div className="bookig-details-grid">
                            <div className="bookig-details-item">
                              <div className="content-wrap">
                                <span>User Name</span>
                                <div className="bookig-detail">
                                  {basicDetails.userName}
                                </div>
                              </div>
                            </div>
                            <div className="bookig-details-item">
                              <div className="content-wrap">
                                <span>Email Address</span>
                                <div className="bookig-detail">
                                  {basicDetails.emailAddress}
                                </div>
                              </div>
                            </div>
                            <div className="bookig-details-item">
                              <div className="content-wrap">
                                <span>Phone Number</span>
                                <div className="bookig-detail">
                                  {basicDetails.contactNo}
                                </div>
                              </div>
                            </div>
                            <div className="bookig-details-item">
                              <div className="content-wrap">
                                <span>Moving Date</span>
                                <div className="bookig-detail">
                                  {formatDate(basicDetails.moveInDate)}
                                </div>
                              </div>
                            </div>
                            <div className="bookig-details-item">
                              <div className="content-wrap">
                                <span>Flat Number</span>
                                <div className="bookig-detail">
                                  {basicDetails.flatNo}
                                </div>
                              </div>
                            </div>
                            <div className="bookig-details-item">
                              <div className="content-wrap">
                                <span>Property Name</span>
                                <div className="bookig-detail">
                                  {basicDetails.propertyName}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="bookig-details-item mt-3">
                            <div className="content-wrap-property">
                              <span>Property Address</span>
                              <div className="bookig-detail-address">
                                {basicDetails.propertyAddress}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="right-col">
                      <div className="all-payment-amount-details mb-5">
                        <div className="item">
                          <div className="content-wrap">
                            <div className="title">
                              <h3>Total Booking Amount</h3>
                              <p>Pay online directly</p>
                            </div>
                            <div className="amount-charges">
                              <span>
                                <FontAwesomeIcon icon={faInr} />
                              </span>
                              {basicDetails.orderAmount}
                            </div>
                          </div>
                        </div>
                        <div className="item">
                          <div className="content-wrap">
                            <div className="title">
                              <h3>Balance Amount</h3>
                              <p>
                                Pay online to sowerent, refunded at move-out
                              </p>
                            </div>
                            <div className="amount-charges">
                              <span>
                                <FontAwesomeIcon icon={faInr} />
                              </span>
                              {basicDetails.balanceAmount}
                            </div>
                          </div>
                        </div>
                        <div className="payable-amount item">
                          <div className="content-wrap">
                            <div className="title">
                              <h3>Total Amount to Pay Now</h3>
                              <p>One time payable</p>
                            </div>
                            <div className="amount-charges">
                              <span>
                                <FontAwesomeIcon icon={faInr} />
                              </span>
                              {basicDetails.paidAmount}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* {isDataLoaded && ( */}
                      <div className="text-center d-flex justify-content-end">
                        <Button
                          className="proceed-button"
                          onClick={handlePayment}
                          disabled={isPaynowClicked}
                        >
                          {isPaynowClicked ? (
                            <CircularProgress color="#ffffff" size={20} />
                          ) : (
                            "Pay Now"
                          )}
                        </Button>
                      </div>
                      {/* )} */}
                    </div>
                  </div>
                )}
              </Col>
            ) : (
              <Col>
                <h4 className="please-wait-loader">
                  Do not refresh the page. Please wait...
                </h4>
              </Col>
            )}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Checkout;
