import apiClient from "../axios.config";

const ownerDocsServices = {
  getDocumentInfo(payload) {
    return apiClient.get("owner/docs/all", payload);
  },
  deleteDocumentInfo(documentId) {
    return apiClient.delete(`owner/docs/${documentId}`);
  },
  uploadDocument(payload) {
    return apiClient.post("owner/docs/add", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export default ownerDocsServices;
