import React, { useState, useEffect } from "react";
import { Col, Form, Row, Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import mykycServices from "../../services/customer-services/my-kyc-services";
import { handleErrors } from "../../utils/errorHandler";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useUser } from "../context/UserContext";

const aadharRegex = /^\d{12}$/;
const MyKyc = () => {
  const { user } = useUser();
  const [aadhar, setAadhar] = useState("");
  const [pan, setPan] = useState("");
  const [drivingLicense, setDrivingLicense] = useState("");
  const [passportFileNo, setPassportFileNo] = useState("");
  const [passportNo, setPassportNo] = useState("");
  const [visaValidity, setVisaValidity] = useState("");
  const [aadharFiles, setAadharFiles] = useState([]);
  const [panFiles, setPanFiles] = useState([]);
  const [showOtpModal, setShowOtpModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [aadharReference, setAadharReference] = useState(null);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false); // New state variable
  const [isVerifyingPassport, setIsVerifyingPassport] = useState(false); // New state variable for passport verification

  const [uploadResponse, setUploadResponse] = useState({
    aadhar: "",
    pan: "",
  });

  // State to store fetched document URLs
  const [fetchedFiles, setFetchedFiles] = useState({
    aadharFile: "",
    aadharFileBack: "",
    panFile: "",
  });

  // Verification status
  const [verificationStatus, setVerificationStatus] = useState({
    aadhar: false,
    pan: false,
    drivingLicense: false,
    passportFileNo: false,
    visaValidity: false,
    docVerifyStatus: false,
  });

  const isValidDateFormat = (dateString) => {
    // Regular expression to match the format dd-mmm-yyyy
    const regex =
      /^(0[1-9]|[12][0-9]|3[01])-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-\d{4}$/;
    return regex.test(dateString);
  };

  const fetchKycData = async () => {
    try {
      const response = await mykycServices.getMykyc();
      if (response.data.isSuccess) {
        const kycData = response.data.result;
        setAadhar(kycData.aadharNo || "");
        setPan(kycData.pan || "");
        setDrivingLicense(kycData.drivingLicense || "");
        setPassportFileNo(kycData.passportFileNo || "");
        setPassportNo(kycData.passportNo || "");
        setVisaValidity(kycData.visaValidity || "");

        // Set verification statuses
        setVerificationStatus({
          aadhar: kycData.aadharNoStatus === "Verified",
          pan: kycData.panStatus === "Verified",
          drivingLicense: kycData.drivingLicenseStatus === "Verified",
          passportFileNo: kycData.passportNoStatus === "Verified",
          visaValidity: kycData.passportNoStatus === "Verified",
          docVerifyStatus: kycData.docVerifyStatus === "Verified",
        });

        // Set fetched file URLs in fetchedFiles state
        setFetchedFiles({
          aadharFile: kycData.aadharFile || "",
          aadharFileBack: kycData.aadharFileBack || "",
          panFile: kycData.panFile || "",
        });
      }
    } catch (error) {
      let errorMessage = handleErrors(error);
      if (error.response) {
        if (error.response.status === 500) {
          errorMessage = error.response.data.message;
        }
      }
      toast.error(errorMessage);
    }
  };
  // Fetch data when the component mounts
  useEffect(() => {
    fetchKycData();
  }, []);

  const handleAadharChange = async (e) => {
    const value = e.target.value;
    setAadhar(value);
    if (aadharRegex.test(value)) {
      try {
        const response = await mykycServices.postAadhar({ aadharNo: value });
        if (response.data.isSuccess) {
          toast.success(response.data.message);

          // Extract and store reference_id and transaction_id
          const { reference_id, transaction_id } = response.data.result;
          setAadharReference({ reference_id, transaction_id });

          setShowOtpModal(true); // Open OTP modal
        } else {
          toast.error("Failed to request OTP.");
        }
      } catch (error) {
        let errorMessage = handleErrors(error);
        if (error.response) {
          if (error.response.status === 500) {
            errorMessage = error.response.data.message;
          }
        }
        toast.error(errorMessage);
      }
    }
  };

  const verifyOtp = async () => {
    if (
      otp.length === 6 &&
      aadharReference?.reference_id &&
      aadharReference?.transaction_id
    ) {
      setIsVerifyingOtp(true);
      try {
        const payload = {
          reference_id: aadharReference.reference_id,
          transaction_id: aadharReference.transaction_id,
          otp,
        };

        // Make the API call
        const response = await mykycServices.postOtpAadhar(payload);
        if (response.data.isSuccess) {
          setIsOtpVerified(true); // Mark OTP as verified
          setVerificationStatus((prev) => ({ ...prev, aadhar: true }));
          setShowOtpModal(false); // Close OTP modal
          toast.success("Aadhar verified successfully!");
        } else {
          toast.error("Invalid OTP. Please try again.");
        }
      } catch (error) {
        let errorMessage = handleErrors(error);
        if (error.response) {
          if (error.response.status === 500) {
            errorMessage = error.response.data.message;
          }
        }
        toast.error(errorMessage);
      } finally {
        setIsVerifyingOtp(false);
      }
    } else {
      toast.error("OTP or reference details missing.");
    }
  };

  const handlePanChange = async (e) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    const value = e.target.value;
    setPan(value);

    // Real-time validation feedback
    if (value.length === 10 && !panRegex.test(value)) {
      toast.error("Please add a valid PAN"); // Show validation message
      setVerificationStatus((prev) => ({ ...prev, pan: false })); // Mark as invalid
      return;
    }

    // API call when length is 10 and format is valid
    if (value.length === 10 && panRegex.test(value)) {
      try {
        await mykycServices.postPan({ pan: value });
        toast.success("PAN number added successfully!");
        setVerificationStatus((prev) => ({ ...prev, pan: true })); // Mark as valid
      } catch (error) {
        let errorMessage = handleErrors(error);
        if (error.response && error.response.status === 500) {
          errorMessage = error.response.data.message;
        }
        toast.error(errorMessage);
        setVerificationStatus((prev) => ({ ...prev, pan: false })); // Mark as invalid
      }
    }
  };

  const handleDrivingLicenseChange = async (e) => {
    const dlRegex =
      /^(([A-Z]{2}[0-9]{2})( )|([A-Z]{2}-[0-9]{2}))((19|20)[0-9]{2})[0-9]{7}$/;
    const value = e.target.value;
    setDrivingLicense(value);

    // Real-time validation feedback
    if (value.length === 16 && !dlRegex.test(value)) {
      toast.error("Please add a valid Driving License"); // Show validation message
      setVerificationStatus((prev) => ({ ...prev, drivingLicense: false })); // Mark as invalid
      return;
    }

    // API call when length is 16 and format is valid
    if (value.length === 16 && dlRegex.test(value)) {
      try {
        const payload = {
          drivingLicense: value,
          dob: user?.userInfo?.dob || "",
        };

        await mykycServices.postDrivinglicense(payload);
        toast.success("Driving license added successfully!"); // Success toast
        setVerificationStatus((prev) => ({ ...prev, drivingLicense: true })); // Mark as valid
      } catch (error) {
        let errorMessage = handleErrors(error);
        if (error.response && error.response.status === 500) {
          errorMessage = error.response.data.message;
        }
        toast.error(errorMessage);
        setVerificationStatus((prev) => ({ ...prev, drivingLicense: false })); // Mark as invalid
      }
    }
  };

  const handlePassportChange = (e) => {
    setPassportFileNo(e.target.value);
  };

  const handleVerifyPassportAndVisa = async () => {
    const passportRegex = /^[A-Z]{2}\d{13}$/; // Passport validation regex
    const visaValidityRegex = /^\d{2}-[a-zA-Z]{3}-\d{4}$/i; // Case-insensitive month validation

    // Validate Passport File Number
    if (!passportRegex.test(passportFileNo)) {
      toast.error("Invalid Passport File Number.");
      setVerificationStatus((prev) => ({ ...prev, passportFileNo: false }));
      return;
    }

    // Validate Visa Validity
    if (!visaValidity) {
      toast.error("Visa Validity is required.");
      setVerificationStatus((prev) => ({ ...prev, visaValidity: false }));
      return;
    }

    if (!visaValidityRegex.test(visaValidity)) {
      toast.error(
        "Invalid Visa Validity format. Please enter in the format: DD-MMM-YYYY."
      );
      setVerificationStatus((prev) => ({ ...prev, visaValidity: false }));
      return;
    }
    setIsVerifyingPassport(true);
    // API call logic
    try {
      const payload = {
        passportFileNo: passportFileNo,
        visaValidity: visaValidity,
      };

      // Call the API
      const response = await mykycServices.postPassportno(payload);

      if (response.data.isSuccess) {
        toast.success("Passport and Visa Validity verified successfully!");
        setVerificationStatus((prev) => ({
          ...prev,
          passportFileNo: true,
          visaValidity: true,
        }));
      } else {
        toast.error("Verification failed. Please try again.");
      }
    } catch (error) {
      let errorMessage = handleErrors(error);
      if (error.response && error.response.status === 500) {
        errorMessage = error.response.data.message;
      }
      toast.error(errorMessage);
    } finally {
      // Reset loading state
      setIsVerifyingPassport(false);
    }
  };

  const handleFileChange = async (e, setFiles, documentType) => {
    const files = Array.from(e.target.files);
    const maxFileSize = 2 * 1024 * 1024;

    const validFiles = files.filter((file) => {
      if (file.size > maxFileSize) {
        toast.error(`${file.name} exceeds the 2 MB file size limit.`);
        return false;
      }
      return true;
    });

    setFiles(validFiles);

    // Loop through each valid file and upload it
    for (const file of validFiles) {
      const formData = new FormData();
      formData.append("DocumentType", documentType);
      formData.append("Document", file);

      try {
        const response = await mykycServices.postUploadkycdoc(formData);
        setUploadResponse((prev) => ({
          ...prev,
          [documentType.toLowerCase()]: response.data.result,
        }));
        toast.success(`${documentType} file uploaded successfully!`);
        fetchKycData();
      } catch (error) {
        let errorMessage = handleErrors(error);
        if (error.response) {
          if (error.response.status === 500) {
            errorMessage = error.response.data.message;
          }
        }
        toast.error(errorMessage);
      }
    }
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };
  const allVerified = Object.values(verificationStatus).every(
    (status) => status
  );

  return (
    <div className="my-kyc-wrap">
      <div className="my-account-title">
        <h2>
          My KYC
          {allVerified && (
            <FontAwesomeIcon
              icon={faCheckCircle}
              color="green"
              className="ms-2"
            />
          )}
        </h2>
      </div>
      <div className="profile-info-wrap">
        <Row>
          {user?.userInfo?.nationality === "Indian" && (
            <>
              <Col xs={12} xl={4} lg={6} sm={6}>
                <Form.Label>
                  Aadhar Card Number
                  <span className="verified">
                    <FontAwesomeIcon
                      icon={
                        verificationStatus.aadhar
                          ? faCheckCircle
                          : faTimesCircle
                      }
                      color={verificationStatus.aadhar ? "green" : "red"}
                    />
                  </span>
                </Form.Label>
                <Form.Control
                  type="number"
                  placeholder="123456781234"
                  value={aadhar}
                  onChange={(e) => {
                    const input = e.target.value;
                    if (input.length <= 12) {
                      handleAadharChange(e);
                    }
                  }}
                  maxLength={12}
                  disabled={verificationStatus.aadhar} // Disable input once verified
                />
              </Col>

              <Col xs={12} xl={4} lg={6} sm={6}>
                <Form.Label>
                  Pan Number
                  <span className="verified">
                    <FontAwesomeIcon
                      icon={
                        verificationStatus.pan ? faCheckCircle : faTimesCircle
                      }
                      color={verificationStatus.pan ? "green" : "red"}
                    />
                  </span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="ABCDE1234F"
                  value={pan}
                  onChange={handlePanChange}
                  maxLength={10}
                  disabled={verificationStatus.pan}
                />
              </Col>
              <Col xs={12} xl={4} lg={6} sm={6}>
                <Form.Label>
                  Driving License
                  <span className="verified">
                    <FontAwesomeIcon
                      icon={
                        verificationStatus.drivingLicense
                          ? faCheckCircle
                          : faTimesCircle
                      }
                      color={
                        verificationStatus.drivingLicense ? "green" : "red"
                      }
                    />
                  </span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="DL14 20110012345"
                  value={drivingLicense}
                  maxLength={16}
                  onChange={handleDrivingLicenseChange}
                  disabled={verificationStatus.drivingLicense}
                />
              </Col>
            </>
          )}
          {(user?.userInfo?.nationality === "NRI" ||
            user?.userInfo?.nationality === "Foreigner") && (
            <>
              <Col xs={12} xl={4} lg={6} sm={6} className="mt-3">
                <Form.Label>
                  Passport File Number
                  <span className="verified">
                    <FontAwesomeIcon
                      icon={
                        verificationStatus.passportFileNo
                          ? faCheckCircle
                          : faTimesCircle
                      }
                      color={
                        verificationStatus.passportFileNo ? "green" : "red"
                      }
                    />
                  </span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="BN1063810563721"
                  value={passportFileNo}
                  onChange={handlePassportChange}
                  disabled={verificationStatus.passportFileNo}
                />
              </Col>
              <Col xs={12} xl={4} lg={6} sm={6} className="mt-3">
                <Form.Label>
                  Visa Validity
                  <span className="verified">
                    <FontAwesomeIcon
                      icon={
                        verificationStatus.visaValidity
                          ? faCheckCircle
                          : faTimesCircle
                      }
                      color={verificationStatus.visaValidity ? "green" : "red"}
                    />
                  </span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="DD-MMM-YYYY"
                  value={visaValidity}
                  onChange={(e) => setVisaValidity(e.target.value)}
                  disabled={verificationStatus.passportFileNo}
                />
              </Col>
              {!verificationStatus.passportFileNo && (
                <Col xs={12} xl={4} lg={6} sm={6} className="mt-3">
                  <Button
                    className="verify-passport btn-theme-blue mt-4 py-1"
                    onClick={handleVerifyPassportAndVisa}
                    disabled={
                      verificationStatus.passportFileNo || isVerifyingPassport
                    }
                  >
                    {isVerifyingPassport
                      ? "Verifying..."
                      : "Verify Passport & Visa"}
                  </Button>
                </Col>
              )}
              {verificationStatus.passportFileNo && (
                <Col xs={12} xl={4} lg={6} sm={6} className="mt-3">
                  <Form.Label>Passport Number</Form.Label>
                  <Form.Control type="text" value={passportNo} />
                </Col>
              )}
            </>
          )}
        </Row>
        {/* File Upload Section */}
        <div className="dotted-file-upload-wrap">
          <div className="file-upload-wrap">
            <Form.Label>
              {user?.userInfo?.nationality === "Indian"
                ? "Upload Aadhar Front"
                : "Upload Passport Front"}
              <FontAwesomeIcon
                className="ms-2"
                icon={
                  verificationStatus.docVerifyStatus
                    ? faCheckCircle
                    : faTimesCircle
                }
                color={verificationStatus.docVerifyStatus ? "green" : "red"}
              />
            </Form.Label>

            <div className="dotted-upload">
              <input
                type="file"
                accept=".pdf, .jpg, .jpeg, .png"
                onChange={(e) => handleFileChange(e, setAadharFiles, "Aadhar")}
                className="file-input"
                disabled={verificationStatus.docVerifyStatus}
              />
              {user?.userInfo?.nationality === "Indian"
                ? "Upload Aadhar files Front here click to upload"
                : "Upload PassPort files Front here click to upload"}

              {aadharFiles.map((file, index) => (
                <></>
              ))}
            </div>
          </div>
          <div className="file-upload-wrap">
            <Form.Label>
              {user?.userInfo?.nationality === "Indian"
                ? "Upload Aadhar Back"
                : "Upload Passport Back"}
              <FontAwesomeIcon
                className="ms-2"
                icon={
                  verificationStatus.docVerifyStatus
                    ? faCheckCircle
                    : faTimesCircle
                }
                color={verificationStatus.docVerifyStatus ? "green" : "red"}
              />
            </Form.Label>
            <div className="dotted-upload">
              <input
                type="file"
                accept=".pdf, .jpg, .jpeg, .png"
                onChange={(e) =>
                  handleFileChange(e, setAadharFiles, "Aadharback")
                }
                className="file-input"
                disabled={verificationStatus.docVerifyStatus}
              />
              {user?.userInfo?.nationality === "Indian"
                ? "Upload Aadhar files Back here click to upload"
                : "Upload PassPort files Back here click to upload"}

              {aadharFiles.map((file, index) => (
                <></>
              ))}
            </div>
          </div>

          <div className="file-upload-wrap">
            <Form.Label>
              Upload PAN Card
              <FontAwesomeIcon
                className="ms-2"
                icon={
                  verificationStatus.docVerifyStatus
                    ? faCheckCircle
                    : faTimesCircle
                }
                color={verificationStatus.docVerifyStatus ? "green" : "red"}
              />
            </Form.Label>
            <div className="dotted-upload">
              <input
                type="file"
                accept=".pdf, .jpg, .jpeg, .png"
                onChange={(e) => handleFileChange(e, setPanFiles, "PAN")}
                className="file-input"
                disabled={verificationStatus.docVerifyStatus}
              />
              <p>Upload Pan card file here click to upload</p>
              {panFiles.map((file, index) => (
                <></>
              ))}
            </div>
          </div>
        </div>

        <span className="span-file-size">
          File size should be less then 2 MB.
        </span>

        {/* Display Uploaded Document Responses */}
        <Row className="mt-4">
          <Col xs={12}>
            <h5>Uploaded Document Responses</h5>
            <div className="upload-data-wrapper">
              <div>
                <strong>
                  {user?.userInfo?.nationality === "Indian"
                    ? " Aadhar Front(Copy)"
                    : " Passport Front(Copy)"}
                </strong>
                <div>{uploadResponse.aadhar}</div>

                {fetchedFiles.aadharFile && (
                  <img
                    src={fetchedFiles.aadharFile}
                    alt="Aadhar"
                    style={{ width: "200px", margin: "10px 0" }}
                  />
                )}
              </div>
              <div>
                <strong>
                  {user?.userInfo?.nationality === "Indian"
                    ? " Aadhar Back(Copy)"
                    : " Passport Back(Copy)"}
                </strong>
                <div>{uploadResponse.aadhar}</div>

                {fetchedFiles.aadharFileBack && (
                  <img
                    src={fetchedFiles.aadharFileBack}
                    alt="Aadhar"
                    style={{ width: "200px", margin: "10px 0" }}
                  />
                )}
              </div>
              <div>
                <strong>PAN Copy:</strong>
                <div>{uploadResponse.pan}</div>
                {fetchedFiles.panFile && (
                  <img
                    src={fetchedFiles.panFile}
                    alt="PAN"
                    style={{ width: "200px", margin: "10px 0" }}
                  />
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Modal show={showOtpModal} onHide={() => setShowOtpModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Aadhar Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Enter OTP</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter 6-digit OTP"
            value={otp}
            onChange={handleOtpChange}
            maxLength={6}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowOtpModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={verifyOtp}
            disabled={otp.length !== 6 || isVerifyingOtp}
          >
            Verify OTP
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MyKyc;
