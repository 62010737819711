import React, { useEffect, useState } from "react";
import auditService from "../../services/owner-services/owner-property-audit";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { Swiper } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { SwiperSlide } from "swiper/react";
const AuditTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    fetchAuditData();
  }, []);

  const fetchAuditData = async () => {
    setLoading(true);
    try {
      const payload = {
        pageSize: 100,
        pageNo: 1,
        fromDate: "",
        toDate: "",
        filterParam: "",
      };
      const response = await auditService.posttPropertyAudit(payload);
      if (response.data.isSuccess) {
        setData(response.data.result);
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
    setLoading(false);
  };

  const fetchAuditImages = async (id) => {
    try {
      const response = await auditService.getPropertyAuditImage(id);
      if (response.data.isSuccess && response.data.result.gallery) {
        setSelectedImages(response.data.result.gallery);
        setShowModal(true);
      }
    } catch (error) {
      console.error("Error fetching images", error);
    }
  };

  const imageTemplate = (rowData) => (
    <Button variant="link" onClick={() => fetchAuditImages(rowData.id)}>
      View
    </Button>
  );

  const propertyNameWithFlatNo = (rowData) => {
    return (
      <div>
        <b> Property Name : </b>
        {rowData.propertyName},
        <br />
        <b> Flat No : </b> {rowData.flatNo}
      </div>
    );
  };
  const formatDated = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(dateString)
      .toLocaleDateString("en-GB", options)
      .replace(/ /g, "-");
  };
  return (
    <div>
      <h3>Property Audit</h3>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Row>
          <Col xs={12}>
            <DataTable
              value={data}
              paginator
              rows={10}
              showGridlines
              stripedRow
            >
              <Column field={propertyNameWithFlatNo} header="Property Name" />
              <Column
                body={(rowData) => formatDated(rowData.auditDate)}
                header="Audit Date"
              />
              <Column field="flatName" header="Flat Name" />
              <Column field="comments" header="Comments" />
              <Column
                body={(rowData) => formatDated(rowData.addedOn)}
                header="Added On"
              />
              <Column field={imageTemplate} header="Images" />
            </DataTable>
          </Col>
        </Row>
      )}
      <Modal show={showModal} onHide={() => setShowModal(false)} size="md">
        <Modal.Header closeButton>
          <Modal.Title>Audit Images</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Swiper
            slidesPerView={1.3}
            modules={[Pagination]}
            spaceBetween={24}
            pagination={{
              clickable: true,
            }}
            className="audit-image-slider"
          >
            {selectedImages.length > 0 ? (
              selectedImages.map((image) => (
                <SwiperSlide key={image.id}>
                  <img
                    src={image.fileLink}
                    alt="Audit"
                    style={{ width: "100%", marginBottom: "10px" }}
                  />
                </SwiperSlide>
              ))
            ) : (
              <p>No images available</p>
            )}
          </Swiper>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AuditTable;
