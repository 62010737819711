import apiClient from "../axios.config";

const ownerForgotPassword = {
  ownerForgot(credentials) {
    return apiClient
      .put("/owner/profile/change-password", credentials)
      .then((response) => {
        return response.data;
      });
  },
};

export default ownerForgotPassword;
