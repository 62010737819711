import apiClient from "../axios.config";

const mykycServices = {
  postAadhar(payload) {
    return apiClient.post("customer/kyc/aadhar", payload);
  },
  postOtpAadhar(payload) {
    return apiClient.post("customer/kyc/aadhar/otp", payload);
  },
  postPan(payload) {
    return apiClient.post("customer/kyc/pan", payload);
  },

  postDrivinglicense(payload) {
    return apiClient.post("customer/kyc/drivinglicense", payload);
  },

  postPassportno(payload) {
    return apiClient.post("customer/kyc/passportno", payload);
  },

  postUploadkycdoc(payload) {
    return apiClient.post("customer/kyc/uploadkycdoc", payload);
  },

  getMykyc() {
    return apiClient.get("customer/kyc/get-kyc");
  },
};
export default mykycServices;
