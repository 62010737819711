export const validateOwnerPersonalInfo = (formData) => {
  let errors = {};

  // First Name Validation
  if (!formData.FirstName.trim()) {
    errors.FirstName = "First Name is required.";
  }

  // Last Name Validation
  if (!formData.LastName.trim()) {
    errors.LastName = "Last Name is required.";
  }
  if (!formData.CompleteAddress.trim()) {
    errors.CompleteAddress = "Complete Address is required.";
  }


  if (!formData.ContactNo || formData.ContactNo.trim() === "") {
    errors.ContactNo = "Phone number is required";
  } else {
    const sanitizedPhone = formData.ContactNo.replace(/\s+/g, "");
    if (!/^\+?[1-9]\d{1,14}$/.test(sanitizedPhone)) {
      errors.ContactNo = "Invalid phone number format";
    }
  }

  if (!formData.EmailAddress) {
    errors.EmailAddress = "Pan number is required";
  } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.EmailAddress)) {
    errors.EmailAddress = "Invalid pan number";
  }

  if (!formData.PANNo) {
    errors.PANNo = "Pan number is required";
  } else if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(formData.PANNo)) {
    errors.PANNo = "Invalid pan number";
  }

  return errors;
};
