export const validateBankDetails = (formData) => {
  const errors = {};

  // Validate Bank Name
  if (!formData.bankName.trim()) {
    errors.bankName = "Bank Name is required";
  } else if (formData.bankName.length > 50) {
    errors.bankName = "Bank Name must be at most 50 characters long";
  }

  // Validate Account Number
  if (!formData.accountNo.trim()) {
    errors.accountNo = "Account No is required";
  } else if (formData.accountNo.length > 30) {
    errors.accountNo = "Account No must be at most 30 characters long";
  }

  // Validate IFSC Code
  if (!formData.ifscCode.trim()) {
    errors.ifscCode = "IFSC Code is required";
  } else if (formData.ifscCode.length > 20) {
    errors.ifscCode = "IFSC Code must be at most 20 characters long";
  }

  // Validate Account Holder Name
  if (!formData.accountName.trim()) {
    errors.accountName = "Account Name is required";
  } else if (formData.accountName.length > 50) {
    errors.accountName = "Account Name must be at most 50 characters long";
  }

  return errors;
};
