import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import ownerPropertyServices from "../../services/owner-services/owner-property-services";
import { handleErrors } from "../../utils/errorHandler";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
const OwnerProperty = () => {
  const [propertyList, setPropertyList] = useState([]);
  const [showTenantModal, setShowTenantModal] = useState(false);
  const [showFlatmateModal, setShowFlatmateModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [tenantData, setTenantData] = useState([]);
  const [flatmateData, setFlatmateData] = useState([]);

  useEffect(() => {
    async function fetchPropertyData() {
      try {
        const response = await ownerPropertyServices.getProperty();
        const totalOwnerRent = response?.data?.result.reduce(
          (sum, property) => sum + property.ownerRent,
          0
        );
        const rent = {
          ownerRent: (
            <>
              <b>Total rent :</b> {totalOwnerRent.toLocaleString()}
            </>
          ),
        };
        const data = [...response.data.result, rent];

        setPropertyList(data || []);
      } catch (error) {
        toast.error(handleErrors(error));
      }
    }

    fetchPropertyData();
  }, []);
  const handleViewTenants = (title, data) => {
    setModalTitle(title);
    setTenantData(data);
    setShowTenantModal(true);
  };

  const handleViewFlatmates = (title, data) => {
    setModalTitle(title);
    setFlatmateData(data);
    setShowFlatmateModal(true);
  };

  const propertyNameWithFlatNo = (rowData) => {
    return (
      <div>
        <b> Property Name : </b>
        {rowData.propertyName},
        <br />
        <b> Flat No : </b> {rowData.flatNo}
        <b> {`(${rowData.bhkType})`}</b> <b>{`(${rowData.flatSize} sq ft)`}</b>
      </div>
    );
  };
  const flatmateName = (rowData) => {
    return (
      <div>
        <div>
          <div>
            {rowData.firstName} {rowData.lastName}
          </div>
          <div>
            <i className="ri-mail-fill"></i>{" "}
            <span style={{ fontWeight: 600 }}>{rowData.emailAddress}</span>
          </div>
          <div>
            <i className="ri-phone-fill"></i>{" "}
            <span style={{ fontWeight: 600 }}>{rowData.contactNo}</span>
          </div>
        </div>
      </div>
    );
  };
  const IdStatus = (rowData) => {
    return (
      <div>
        {rowData.idStatus === "Unverified" ? (
          <span className="badge badge-danger">Unverified</span>
        ) : (
          <span className="badge badge-success">Verified</span>
        )}
      </div>
    );
  };

  const viewTenants = (rowData) => {
    return rowData.tanents ? (
      <Button
        variant="link"
        onClick={() => handleViewTenants("Tenants", [rowData.tanents])}
      >
        View
      </Button>
    ) : (
      "-"
    );
  };

  const viewFlatmates = (rowData) => {
    return rowData.flatmates?.length ? (
      <Button
        variant="link"
        onClick={() => handleViewFlatmates("Flatmates", rowData.flatmates)}
      >
        View
      </Button>
    ) : (
      "-"
    );
  };

  return (
    <>
      <style>
        {`
                  .OwnerRents table tbody tr:last-child td:first-child div, 
                   .OwnerRents table tbody tr:last-child td:nth-child(3) div, 
                   .OwnerRents table tbody tr:last-child td:nth-child(4) div{
                      display: none;
                    }                 
    
 
                `}
      </style>

      <div className="agreement-list">
        <h3>Property List</h3>

        <Row>
          <Col xs={12}>
            <DataTable
              className="OwnerRents"
              value={propertyList}
              paginator
              rows={10}
              showGridlines
              stripedRows
            >
              <Column field={propertyNameWithFlatNo} header="Property" />
              <Column field="ownerRent" header="Rent" />
              <Column field={viewTenants} header="Tenants" />
              <Column field={viewFlatmates} header="Flatmates" />
            </DataTable>
          </Col>
        </Row>
      </div>
      <Modal
        show={showTenantModal}
        onHide={() => setShowTenantModal(false)}
        size="lg"
      >
        <Modal.Body>
          {tenantData.length ? (
            <ul>
              {tenantData.map((item, index) => (
                <ul key={index} className="m-2">
                  <div className="profile-info-fields">
                    <h4>Tenant Details</h4>
                    <li className="tenant-info">
                      <div className="p-2">
                        <div className="contact-label">
                          <b>Tenant Name</b>
                        </div>
                        <div className="contact-value">{item.userName}</div>
                      </div>
                      <div className="p-2">
                        <div className="contact-label">
                          <b>Contact</b>
                        </div>
                        <div className="contact-value">{item.contactNo}</div>
                      </div>
                    </li>
                  </div>
                  <div className="profile-info-fields">
                    <h4>KYC</h4>
                    <li className="tenant-info">
                      <div className="p-2">
                        <div className="contact-label">
                          <b>
                            Aadhar Number{" "}
                            <FontAwesomeIcon
                              icon={
                                item.aadharNoStatus
                                  ? faCheckCircle
                                  : faTimesCircle
                              }
                              color={item.aadharNoStatus ? "green" : "red"}
                            />
                          </b>
                        </div>
                        <div className="contact-value"> {item.aadharNo}</div>
                      </div>
                      <div className="p-2">
                        <div className="contact-label">
                          <b>
                            PAN Number{" "}
                            <FontAwesomeIcon
                              icon={
                                item.panStatus ? faCheckCircle : faTimesCircle
                              }
                              color={item.panStatus ? "green" : "red"}
                            />
                          </b>
                        </div>
                        <div className="contact-value">{item.pan}</div>
                      </div>
                      <div className="p-2">
                        <div className="contact-label">
                          <b>
                            Documents Status{" "}
                            <FontAwesomeIcon
                              icon={
                                item.docVerifyStatus === "Verified"
                                  ? faCheckCircle
                                  : faTimesCircle
                              }
                              color={
                                item.docVerifyStatus === "Verified"
                                  ? "green"
                                  : "red"
                              }
                            />
                          </b>
                        </div>
                        <div className="contact-value">
                          {item.docVerifyStatus}
                        </div>
                      </div>
                    </li>
                  </div>

                  <li className="tenant-info mt-3">
                    <div className="p-2">
                      <div className="contact-label">
                        <b>Aadhar Front</b>
                      </div>
                      <div className="contact-value">
                        <img
                          src={item.aadharFile}
                          alt="Aadhar Front"
                          className="kyc-img"
                        />
                      </div>
                    </div>
                    <div className="p-2">
                      <div className="contact-label">
                        <b>Aadhar Back</b>
                      </div>
                      <div className="contact-value">
                        <img
                          src={item.aadharFileBack}
                          alt="Aadhar Back"
                          className="kyc-img"
                        />
                      </div>
                    </div>
                    <div className="p-2">
                      <div className="contact-label">
                        <b>PAN </b>
                      </div>
                      <div className="contact-value">
                        <img
                          src={item.panFile}
                          alt="PAN File"
                          className="kyc-img"
                        />
                      </div>
                    </div>
                  </li>
                </ul>
              ))}
            </ul>
          ) : (
            <p>No data available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowTenantModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showFlatmateModal}
        onHide={() => setShowFlatmateModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {flatmateData.length ? (
            <DataTable
              value={flatmateData}
              paginator
              rows={5}
              showGridlines
              stripedRows
            >
              <Column field={flatmateName} header="Flatmate Name" />
              <Column field="relationship" header="Relationship" />
              <Column field="gender" header="Gender" />
              <Column field="age" header="Age" />
              <Column field="idType" header="Id Type" />
              <Column field="idNo" header="Id No" />
              <Column field={IdStatus} header="Id Status" />
            </DataTable>
          ) : (
            <p>No data available.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowFlatmateModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OwnerProperty;
