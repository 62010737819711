import apiClient from "../axios.config";

const OwnerInfoServices = {
  getProfileInfo() {
    return apiClient.get("/owner/profile/basic");
  },

  updateOwnerProfileInfo(payload) {
    const formData = new FormData();
    formData.append("FirstName", payload.FirstName);
    formData.append("LastName", payload.LastName);
    formData.append("FatherName", payload.FatherName);
    formData.append("EmailAddress", payload.EmailAddress);
    formData.append("ContactNo", payload.ContactNo);
    formData.append("CompleteAddress", payload.CompleteAddress);
    formData.append("PANNo", payload.PANNo);
    if (payload.ProfileImage) {
      formData.append("ProfileImage", payload.ProfileImage);
    }
    return apiClient.put("/owner/profile/update", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export default OwnerInfoServices;
