import apiClient from "../axios.config";

const ownerAgrementServices = {
  postAgreementInfo(payload) {
    return apiClient.post("owner/agreement/agreement-preview", payload);
  },
  getAgreementInfo(payload) {
    return apiClient.get("/owner/agreement/all", payload);
  },

  updateProfileInfo(payload) {
    return apiClient.put("/owner/profile/basic", payload);
  },
};

export default ownerAgrementServices;
