import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import OwnerSideBar from "../components/owner-account/owner-sidebar";
import { Helmet } from "react-helmet-async";
const OwnerAccount = () => {
  return (
    <>
      <Helmet>
        <title>My Account | Sowerent</title>
        <meta
          name="description"
          content="Update your account details and preferences in your sowerent Technology LLP profile."
        />
      </Helmet>
      <section className="section-padding my-account-section">
        <Container className="booking-container">
          <Row>
            <Col lg={3}>
              <OwnerSideBar />
            </Col>
            <Col lg="9">
              <div className="account-card">
                <Outlet />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default OwnerAccount;
