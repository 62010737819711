import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import ownerAgrementServices from "../../services/owner-services/owner-agreement";
import { handleErrors } from "../../utils/errorHandler";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const OwnerAgreementTable = () => {
  const [agreementList, setAgreementList] = useState([]);

  useEffect(() => {
    async function fetchAgreementData() {
      try {
        const response = await ownerAgrementServices.getAgreementInfo();
        setAgreementList(response.data.agreements || []);
      } catch (error) {
        toast.error(handleErrors(error));
      }
    }

    fetchAgreementData();
  }, []);

  return (
    <div className="agreement-list">
      <h3>Agreements Details</h3>
      <Row>
        <Col xs={12}>
          <DataTable
            value={agreementList}
            paginator
            rows={10}
            showGridlines
            stripedRows
          >
            <Column header="#" body={(rowData, { rowIndex }) => rowIndex + 1} />
            <Column field="propertyState" header="Property" />
            <Column field="UnSigned Doc" header="UnSigned Doc" />
            <Column field="Signed Doc" header="Signed Doc" />
            <Column field="status" header="Status" />
            <Column field="createdOn" header="Created On" />
            <Column field="signedOn" header="Signed On" />
          </DataTable>
        </Col>
      </Row>
    </div>
  );
};

export default OwnerAgreementTable;
