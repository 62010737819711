import axios from "axios";
import Cookies from "js-cookie";

// Create a base Axios instance
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Base URL from .env
  headers: {
    "Content-Type": "application/json;charset=utf8",
    accept: "*/*",
  },
});

// Request Interceptor
apiClient.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          Cookies.remove("accessToken");
          Cookies.remove("userRole");
          window.location.assign("/login");
          return Promise.reject("Invalid credentials");
        default:
          return Promise.reject(error);
      }
    }
    return Promise.reject("Network error or no response from server");
  }
);

export default apiClient;
