import apiClient from "../axios.config";
import Cookies from "js-cookie";

const ownerInfoLogin = {
  ownerLogin(credentials) {
    return apiClient.post("/owner/auth", credentials).then((response) => {
      if (response.data && response.data.result) {
        const { accessToken, role } = response.data.result;
        console.log("accessToken", accessToken);
        Cookies.set("accessToken", accessToken, { expires: 7 });
        Cookies.set("userRole", role, { expires: 7 });
      }
      return response.data;
    });
  },
};

export default ownerInfoLogin;
