import React, { useState, useEffect } from "react";
import { Button, Col, Form, Row, Image } from "react-bootstrap";
import "react-phone-number-input/style.css";
import { validateOwnerPersonalInfo } from "../../utils/validation/owner-info-validation";
import OwnerInfoServices from "../../services/owner-services/owner-personal-info";
import { toast } from "react-toastify";
import { handleErrors } from "../../utils/errorHandler";
// import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { useUser } from "../context/UserContext";
import "react-phone-number-input/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import defaultProfileImage from "../../../src/assets/images/profile-img-default.png";

const OwnerInfo = () => {
  // const navigate = useNavigate();
  const [formData, setFormData] = useState({
    FirstName: "",
    LastName: "",
    FatherName: "",
    EmailAddress: "",
    ContactNo: "",
    CompleteAddress: "",
    PANNo: "",
    ProfileImagePreview: null,
  });
  // const [verificationStatus, setVerificationStatus] = useState({
  //   email: "",
  //   phone: "",
  // });
  const { updateUserProfile } = useUser();

  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  async function fetchProfileData() {
    try {
      const response = await OwnerInfoServices.getProfileInfo();
      const data = response.data.result;
      setFormData({
        FirstName: data.firstName || "",
        LastName: data.lastName || "",
        FatherName: data.fatherName || "",
        EmailAddress: data.emailAddress || "",
        ContactNo: data.contactNo
          ? data.contactNo.startsWith("+")
            ? data.contactNo
            : `+91${data.contactNo}`
          : "",
        CompleteAddress: data.completeAddress || "",
        PANNo: data.panNo || "",
        ProfileImagePreview: data.profileImage || null,
      });
    } catch (error) {
      toast.error(handleErrors(error));
    }
  }

  useEffect(() => {
    fetchProfileData();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async () => {
    const formErrors = validateOwnerPersonalInfo(formData);
    setErrors(formErrors);

    if (Object.keys(formErrors).length === 0) {
      try {
        setIsSaving(true);
        setIsButtonDisabled(true);

        console.log("Sending Payload:", formData);

        const payload = {
          FirstName: formData.FirstName,
          LastName: formData.LastName,
          FatherName: formData.FatherName,
          EmailAddress: formData.EmailAddress,
          ContactNo: formData.ContactNo,
          CompleteAddress: formData.CompleteAddress,
          PANNo: formData.PANNo,
          ProfileImage: formData.ProfileImage,
        };

        console.log("Converted Payload:", payload);

        await OwnerInfoServices.updateOwnerProfileInfo(payload);

        toast.success("Profile updated successfully!");
      } catch (error) {
        console.error("API Error:", error);
        toast.error(handleErrors(error));
      } finally {
        setIsSaving(false);
        setIsButtonDisabled(false);
      }
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        ProfileImage: file,
        ProfileImagePreview: URL.createObjectURL(file),
      }));
    }
  };

  const handlePhoneChange = (value) => {
    console.log("Raw phone number:", value);
    const sanitizedValue = value ? value.replace(/\s+/g, "") : "";
    console.log("Sanitized phone number:", sanitizedValue);
    setFormData((prevData) => ({
      ...prevData,
      ContactNo: sanitizedValue,
    }));
  };

  return (
    <div className="personal-info">
      <div className="my-account-title">
        <h3>Personal Details</h3>
      </div>
      <div className="profile-info-wrap">
        <Row>
          <Col className="text-center mb-3 d-flex justify-content-center position-relative">
            <label
              htmlFor="profileImageUpload"
              className="position-relative d-inline-block"
            >
              <Image
                src={formData.ProfileImagePreview || defaultProfileImage}
                alt="Profile"
                className="border border-light rounded"
                width={150}
                height={150}
                style={{ cursor: "pointer" }}
              />
              <FontAwesomeIcon
                icon={faCamera}
                size="lg"
                className="position-absolute"
                style={{
                  bottom: 5,
                  right: 5,
                  background: "#fff",
                  borderRadius: "50%",
                  padding: "6px",
                }}
              />
            </label>
            <input
              type="file"
              id="profileImageUpload"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Group className="form-group">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Your Name*"
                name="FirstName"
                value={formData.FirstName}
                onChange={handleInputChange}
                isInvalid={!!errors.FirstName}
              />
              <Form.Control.Feedback type="invalid">
                {errors.FirstName}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Your Last Name*"
              name="LastName"
              value={formData.LastName}
              onChange={handleInputChange}
              isInvalid={!!errors.LastName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.LastName}
            </Form.Control.Feedback>
          </Col>
          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Label>Father Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Your father Name*"
              name="FatherName"
              value={formData.FatherName}
              onChange={handleInputChange}
              // isInvalid={!!errors.fatherName}
            />
          </Col>
          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="Your email *"
              name="EmailAddress"
              value={formData.EmailAddress}
              onChange={handleInputChange}
              isInvalid={!!errors.EmailAddress}
            />
            <Form.Control.Feedback type="invalid">
              {errors.EmailAddress}
            </Form.Control.Feedback>
          </Col>

          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Label>Phone Number </Form.Label>
            <Form.Group className="form-group">
              <PhoneInput
                international
                defaultCountry="IN"
                type="text"
                placeholder="Your Phone Number*"
                name="ContactNo"
                value={formData.ContactNo}
                // value={
                //   formData.contactNo
                //     ? `+91 ${formData.contactNo}`
                //     : `+${formData.contactNo}`
                // }
                maxLength={15}
                onChange={handlePhoneChange}
              />

              <Form.Control.Feedback type="invalid">
                {errors.ContactNo}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} xl={4} lg={6} sm={6}>
            <Form.Label>PAN Number </Form.Label>
            <Form.Group className="form-group">
              <Form.Control
                type="text"
                placeholder="Your Pan number *"
                name="PANNo"
                value={formData.PANNo}
                onChange={handleInputChange}
                isInvalid={!!errors.PANNo}
              />

              <Form.Control.Feedback type="invalid">
                {errors.PANNo}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} xl={12}>
            <Form.Label>Complete Address</Form.Label>
            <Form.Group className="form-group">
              <Form.Control
                as="textarea"
                rows={5}
                placeholder="Enter your Street Address*"
                name="CompleteAddress"
                value={formData.CompleteAddress}
                onChange={handleInputChange}
                isInvalid={!!errors.CompleteAddress}
              />
              <Form.Control.Feedback type="invalid">
                {errors.CompleteAddress}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </div>
      <div className="save-btn">
        <Button
          type="button"
          onClick={handleSubmit}
          disabled={isButtonDisabled}
        >
          {isSaving ? "Saving..." : "Save Details"}
        </Button>
      </div>
    </div>
  );
};

export default OwnerInfo;
