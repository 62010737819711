import React, { useCallback, useEffect, useState } from "react";
import Logo from "../../assets/images/sowerent-logo-blue.png";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faBars } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import userIcon from "../../assets/images/icons/user-info.png";
import wishlistIcon from "../../assets/images/icons/wishlist-menu.png";
import assignedPropIcon from "../../assets/images/icons/assigned-property-menu.png";
import billsIcon from "../../assets/images/icons/bills-menu.png";
import supportMenuIcon from "../../assets/images/icons/support-menu.png";
import feedbackIcon from "../../assets/images/icons/reviews-menu.png";
import Cookies from "js-cookie";
import { useUser } from "../context/UserContext";
import { Sidebar } from "primereact/sidebar";
import logout from "../../assets/images/icons/logout-1.png";
import secure from "../../assets/images/icons/secure-1.png";
import information from "../../assets/images/icons/information.png";

const Header = () => {
  const navigate = useNavigate();
  const { user, setAuthentication } = useUser();

  const [navSidebar, setNavSidebar] = useState(false);

  const checkLoginStatus = useCallback(() => {
    const accessToken = Cookies.get("accessToken");
    if (accessToken) {
      setAuthentication(!!accessToken);
    } else {
      setAuthentication(null);
    }
  }, []);
  useEffect(() => {
    checkLoginStatus();
  }, [checkLoginStatus]);
  const handleLogout = () => {
    Cookies.remove("accessToken");
    Cookies.remove("userRole");
    setAuthentication(null);
    navigate("/");
    window.location.reload();
  };
  return (
    <header className="header header-fix">
      <div className="container">
        <nav className="navbar navbar-expand-lg header-nav">
          <div className="header-nav-wrap">
            <div className="navbar-header">
              <Link to="/" className="navbar-brand logo">
                <img src={Logo} className="img-fluid" alt="Logo" />
              </Link>
            </div>
            <ul
              className={`nav header-navbar-rht ${
                user?.isAuthenticated ? "logged-in" : "logged-out"
              }`}
            >
              {!user?.isAuthenticated && (
                <>
                  <li>
                    <Link to={"about-us"}>About Us</Link>
                  </li>
                  <li>
                    <Link to={"contact-us"}>Contact Us</Link>
                  </li>
                  <li>
                    <Link to={"faq"}>Faqs</Link>
                  </li>
                </>
              )}
              <li className="new-property-btn">
                {!user?.isAuthenticated ? (
                  <Link to="list-your-property" className="mob-none">
                    <span className="own-prop">Own a property ?</span> List a
                    property
                  </Link>
                ) : (
                  <Link>
                    {/* <span className="own-prop">Welcome!</span> */}
                    <span style={{ color: "green", paddingRight: "4px" }}>
                      Welcome!{" "}
                    </span>{" "}
                    {user?.userInfo?.firstName}
                  </Link>
                )}
              </li>
              {!user?.isAuthenticated && (
                <li>
                  <Link to="/login" className="btn btn-primary">
                    Login
                  </Link>
                </li>
              )}
              <li className="swr-menu">
                <div className="menu-icon" onClick={() => setNavSidebar(true)}>
                  <FontAwesomeIcon icon={faBars} />
                </div>
                <Sidebar
                  visible={navSidebar}
                  position="right"
                  onHide={() => setNavSidebar(false)}
                  className="nav-sidebar"
                >
                  <div className="sidebar-top-menu-wrap">
                    <div className="wrap" onClick={() => setNavSidebar(false)}>
                      {user?.isAuthenticated && user?.userRole === "Owner" && (
                        <ul className="swr-user-menu">
                          <li>
                            <Link to="owner-account/ownerprofile">
                              <img src={userIcon} alt="" /> Owner Profile
                            </Link>
                          </li>

                          <li>
                            <Link to="owner-account/owner-profile-agreement">
                              <img src={billsIcon} alt="" />
                              Agreement Details
                            </Link>
                          </li>
                          <li>
                            <Link to="owner-account/owner-bank-details">
                              <img src={information} alt="" />
                              Bank Details
                            </Link>
                          </li>
                          <li>
                            <Link to="owner-account/owner-profile-docs">
                              <img src={feedbackIcon} alt="" />
                              Documents
                            </Link>
                          </li>
                          <li>
                            <Link to="owner-account/owner-property">
                              <img src={assignedPropIcon} alt="" />
                              Property Details
                            </Link>
                          </li>
                          <li>
                            <Link to="owner-account/property-audit">
                              <img src={wishlistIcon} alt="" />
                              Property Audit
                            </Link>
                          </li>
                        </ul>
                      )}
                      {user?.isAuthenticated && user?.userRole !== "Owner" && (
                        <ul className="swr-user-menu">
                          <li>
                            <Link to="my-account/profile">
                              <img src={userIcon} alt="" /> My Profile
                            </Link>
                          </li>
                          <li>
                            <Link to="my-account/wishlist">
                              <img src={wishlistIcon} alt="" /> Wishlist
                            </Link>
                          </li>
                          <li>
                            <Link to="my-account/assigned-property">
                              <img src={assignedPropIcon} alt="" />
                              Assigned Property
                            </Link>
                          </li>
                          <li>
                            <Link to="my-account/bills-payments">
                              <img src={billsIcon} alt="" />
                              Bills & Payments Info
                            </Link>
                          </li>
                          <li>
                            <Link to="my-account/support-request">
                              <img src={supportMenuIcon} alt="" />
                              Support
                            </Link>
                          </li>
                          <li>
                            <Link to="my-account/feedback">
                              <img src={feedbackIcon} alt="" />
                              Feedback
                            </Link>
                          </li>
                        </ul>
                      )}
                      <ul className="ser-main-menu">
                        <li>
                          <Link to="about-us">About</Link>
                        </li>
                        <li>
                          <Link to="blogs">Blogs</Link>
                        </li>
                        <li>
                          <Link to="faq">Faq</Link>
                        </li>
                        <li>
                          <Link to="terms-conditions">
                            Terms &amp; Conditions
                          </Link>
                        </li>
                        <li>
                          <Link to="privacy-policy">Privacy Policy</Link>
                        </li>
                        <li>
                          <Link to="cancellation-policy">
                            Cancellation Policy
                          </Link>
                        </li>
                        <li>
                          <Link to="contact-us">Contact Us</Link>
                        </li>
                      </ul>
                    </div>
                    <div className="wrap" onClick={() => setNavSidebar(false)}>
                      {!user?.isAuthenticated && (
                        <Link to="/ownerlogin" className="btn btn-primary">
                          Owner Login
                        </Link>
                      )}
                    </div>
                    {user?.isAuthenticated && (
                      <div className="logout-btn">
                        <Button onClick={handleLogout}>Logout</Button>
                      </div>
                    )}
                  </div>
                </Sidebar>
              </li>
            </ul>
          </div>
        </nav>
      </div>
      {!user?.isAuthenticated && (
        <div className="mobile-strip mob-show">
          <Link to="list-your-property">
            <span className="own-prop">Own a property ?</span> List a property{" "}
            <FontAwesomeIcon icon={faArrowRight} />
          </Link>
        </div>
      )}
    </header>
  );
};
export default Header;
