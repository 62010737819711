import React from "react";
import { NavLink } from "react-router-dom";
import userIcon from "../../assets/images/icons/user-info.png";
import wishlistIcon from "../../assets/images/icons/wishlist-menu.png";
import assignedPropIcon from "../../assets/images/icons/assigned-property-menu.png";
import billsIcon from "../../assets/images/icons/bills-menu.png";
import logout from "../../assets/images/icons/logout-1.png";
import secure from "../../assets/images/icons/secure-1.png";
import information from "../../assets/images/icons/information.png";
import Cookies from "js-cookie";
import feedbackIcon from "../../assets/images/icons/reviews-menu.png";
import { useUser } from "../context/UserContext";
import { useNavigate } from "react-router-dom";

const OwnerSideBar = () => {
  const navigate = useNavigate();

  const { setAuthentication } = useUser();
  const handleLogout = () => {
    Cookies.remove("accessToken");
    Cookies.remove("userRole");
    setAuthentication(null);
    navigate("/");
    window.location.reload();
  };
  return (
    <div className="my-account-sidebar">
      <ul className="sidebar-list">
        <li>
          <NavLink to="ownerprofile">
            <img src={userIcon} alt="" /> Owner Profile
          </NavLink>
        </li>
        <li>
          <NavLink to="owner-change-password">
            <img src={secure} alt="" /> Change Password
          </NavLink>
        </li>
        <li>
          <NavLink to="owner-profile-agreement">
            <img src={billsIcon} alt="" /> Agreement Details
          </NavLink>
        </li>
        <li>
          <NavLink to="owner-bank-details">
            <img src={information} alt="" /> Bank Details
          </NavLink>
        </li>
        <li>
          <NavLink to="owner-profile-docs">
            <img src={feedbackIcon} alt="" /> Documents
          </NavLink>
        </li>
        <li>
          <NavLink to="owner-property">
            <img src={assignedPropIcon} alt="" /> Property Details
          </NavLink>
        </li>
        <li>
          <NavLink to="property-audit">
            <img src={wishlistIcon} alt="" /> Property Audit
          </NavLink>
        </li>
        <li>
          <NavLink to="/" onClick={handleLogout}>
            <img src={logout} alt="" /> Logout
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default OwnerSideBar;
